import React, { useEffect } from "react";
import Spin from "@/shared/components/spin";
import { useNavigate } from "react-router-dom";

const Page: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/manage`);
  }, []);

  return <Spin />;
};

export default Page;
