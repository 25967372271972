import React from "react";
import { ProFormItemProps, ProFormText } from "@ant-design/pro-components";
import MaskedInput from "antd-mask-input";
import styled from "styled-components";
import { GlobalToken, theme } from "antd";
import useTheme from "@/shared/theme/lib/use";

const StyledThemeInputWrapper = styled.div<{
  token: GlobalToken;
  themecontext: string;
}>`
  width: 100%;

  .ant-input-affix-wrapper:hover {
    border-color: ${({ token, themecontext }) => {
      if (themecontext === "light") return token!["blue-5"];
      else return token!["blue-7"];
    }};
  }

  .ant-input-affix-wrapper {
    background-color: ${({ token }) => token!["colorBgContainer"]};
    border-color: ${({ token }) => token!["colorBorder"]};
  }

  .ant-input-affix-wrapper:focus-within {
    border-color: ${({ token }) => token!["blue-6"]};
    box-shadow: ${({ token, themecontext }) => {
      if (themecontext === "light") return "none";
      else return token.boxShadowTertiary;
    }};
    outline: 0;
  }

  .ant-input-affix-wrapper input {
    background-color: ${({ token }) => token!["colorBgContainer"]};
    color: ${({ token }) => token!["colorText"]};
  }

  .ant-input-affix-wrapper input::placeholder {
    color: ${({ token }) => token!["colorTextQuaternary"]};
  }

  .ant-input-affix-wrapper .ant-input-clear-icon:hover {
    color: ${({ token }) => token!["colorTextTertiary"]};
  }

  .ant-input-affix-wrapper .ant-input-clear-icon {
    color: ${({ token }) => token!["colorTextQuaternary"]};
  }

  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: ${({ token, themecontext }) => {
      if (themecontext === "dark") return token!["colorErrorBorderHover"];
      else return token!["red-3"];
    }};
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: ${({ token, themecontext }) => {
      if (themecontext === "dark") return token!["colorErrorText"];
      else return token!["red-5"];
    }};
  }

  .ant-input-affix-wrapper-disabled {
    background-color: ${({ token }) => token["colorBgContainerDisabled"]};
  }

  .ant-input-affix-wrapper-disabled:hover {
    background-color: ${({ token }) => token["colorBgContainerDisabled"]};
    border-color: ${({ token }) => token.colorBorder};
  }
`;

type ProFormInputMaskProps = Omit<ProFormItemProps, "children"> &
  Pick<React.ComponentProps<typeof MaskedInput>, "mask" | "placeholder">;

const ProFormInputMask: React.FC<ProFormInputMaskProps> = ({
  mask,
  placeholder,
  allowClear = true,
  disabled,
  ...props
}) => {
  const { value: themeContext } = useTheme();
  const { token } = theme.useToken();

  return (
    <StyledThemeInputWrapper token={token} themecontext={themeContext}>
      <ProFormText {...props}>
        <MaskedInput
          mask={mask}
          disabled={disabled}
          placeholder={placeholder}
          allowClear={allowClear}
          maskOptions={{
            lazy: true,
          }}
          {...props.fieldProps}
        />
      </ProFormText>
    </StyledThemeInputWrapper>
  );
};

export default ProFormInputMask;
export type { ProFormInputMaskProps };
