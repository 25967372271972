import { ReactNode } from "react";
import { Import } from "@/models.ts";

export const typeValueEnum: Record<Import["type"], ReactNode> = {
  members: "Пользователи",
  registry: "Номера из реестра",
  position_learning_rules: "Правила обучения",
  questions: "Вопросы",
  course: "Курс",
};
