import React from "react";
import { ButtonProps } from "antd/es/button";
import { Pool } from "@/models";
import { Button, message, Modal } from "antd";
import axios from "@/axios";
import { ModalFuncProps } from "antd/es/modal/interface";

type PoolUpdateButtonProps = ButtonProps & {
  poolId?: Pool["id"];
  poolsIds?: Pool["id"][];
  afterUpdate?: () => void;
  onBeforeUpdate?: () => boolean;
  confirmModalProps?: ModalFuncProps;
  newValue: Partial<Pool>;
};

const PoolUpdateButton: React.FC<PoolUpdateButtonProps> = ({
  poolId,
  poolsIds = [],
  afterUpdate,
  onBeforeUpdate,
  confirmModalProps,
  newValue,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();

  if (poolId && !poolsIds.includes(poolId)) {
    poolsIds.push(poolId!);
  }

  const resources: { [key: string]: Partial<Pool> } = {};
  poolsIds.forEach((poolId) => {
    resources[poolId] = newValue;
  });

  const poolsWord = poolsIds.length > 1 ? "потоки" : "поток";

  const onClick = () => {
    if (onBeforeUpdate?.()) return;
    modal.confirm({
      title: `Вы уверены, что хотите обновить ${poolsWord}?`,
      okText: "Да",
      okType: "danger",
      cancelText: "Отмена",
      onOk: async () =>
        await axios
          .patch(`/api/pools/batch`, { resources })
          .then(() => afterUpdate?.())
          .catch((err) => {
            message.error(err.response.data.message);
          }),
      ...confirmModalProps,
    });
  };

  return (
    <>
      <Button
        disabled={poolsIds.length === 0}
        type="primary"
        onClick={onClick}
        children="Обновить"
        {...props}
      />
      {contextHolder}
    </>
  );
};

export default PoolUpdateButton;
export type { PoolUpdateButtonProps };
