import React from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Pool } from "@/models";
import { deepmerge } from "deepmerge-ts";
import useSWR from "swr";
import { debounce } from "lodash";
import { Flex, Skeleton, Typography } from "antd";
import PoolTemplateIcon from "@/entities/pool-template/ui/icon";

type SelectProps = ProFormSelectProps;

type PoolSelectProps = SelectProps & {
  courseId?: number;
  listName?: SelectProps["name"];
  poolDisabled?: boolean;
};

const PoolSelectWithPoolTemplates: React.FC<PoolSelectProps> = ({
  courseId,
  listName,
  poolDisabled,
  fieldProps,
  formItemProps,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const [initialValue, setInitialValue] = React.useState("");

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const [searchValue, setSearchValue] = React.useState("");
  const {
    data: pools,
    isLoading,
    error,
  } = useSWR(
    ["/api/pools/search", searchValue, courseId, initialValue, poolDisabled],
    async ([url]) => {
      const filters: any = [];
      if (courseId) {
        filters.push({
          field: "course_id",
          operator: "=",
          value: courseId,
        });
      }

      filters.push({
        field: "name",
        operator: "ilike",
        value: `%${searchValue}%`,
      });

      let pools: any = [];

      if (!poolDisabled) {
        pools = await axios
          .post<{ data: Pool[] }>(url, {
            filters: [
              ...filters,
              {
                field: "is_unlimited",
                operator: "=",
                value: true,
              },
              {
                field: "status",
                operator: "!=",
                value: "completed",
              },
              {
                field: "deleted_at",
                operator: "=",
                value: null,
              },
            ],
            sort: [{ field: "created_at", direction: "desc" }],
          })
          .then(async (res) => {
            const data: any = [];
            res.data.data.forEach((pool) => {
              data.push({
                label: pool.name,
                value: `pool#${pool.id}`,
                type: "pool",
              });
            });

            if (
              initialValue &&
              initialValue.startsWith("pool#") &&
              !data.some((obj: any) => obj.value === initialValue)
            ) {
              const currentData = await axios
                .get(`/api/pools/${initialValue}`)
                .then(({ data }) => ({
                  label: data.data.name,
                  value: `pool#${data.data.id}`,
                  disabled: poolDisabled,
                  type: "pool",
                }));
              data.unshift(currentData);
            }

            return data;
          });
      }

      const poolTemplates = await axios
        .post<{ data: Pool[] }>("/api/pool-templates/search", {
          filters,
          sort: [{ field: "created_at", direction: "desc" }],
        })
        .then(async (res) => {
          const data: any = [];
          res.data.data.forEach((poolTemplate) => {
            data.push({
              label: poolTemplate.name,
              value: `pool_template#${poolTemplate.id}`,
              type: "pool_template",
            });
          });

          if (
            initialValue &&
            initialValue.startsWith("pool_template#") &&
            !data.some((obj: any) => obj.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/pool-templates/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: `pool_template#${data.data.id}`,
                type: "pool_template",
              }));
            data.unshift(currentData);
          }

          return data;
        });

      return [...pools, ...poolTemplates];
    },
  );

  if (error) throw error;

  const defaultSelectProps: SelectProps = {
    showSearch: true,
  };

  const onSearch = debounce((value) => setSearchValue(value), 500);

  return (
    <>
      {isLoading && (
        <Flex vertical gap={8}>
          {selectProps.label && <span>{selectProps.label}</span>}
          <Skeleton.Input active block />
        </Flex>
      )}
      <ProFormSelect
        {...deepmerge(defaultSelectProps, selectProps)}
        formItemProps={{
          style: {
            margin: 0,
            visibility: isLoading ? "hidden" : "visible",
            height: isLoading ? 0 : "auto",
          },
          ...formItemProps,
        }}
        fieldProps={{
          onSearch: onSearch,
          optionItemRender: (item: any) => (
            <Flex gap={4}>
              {item.type === "pool_template" && <PoolTemplateIcon />}
              <Typography.Text ellipsis> {item.label}</Typography.Text>
            </Flex>
          ),
          ...fieldProps,
        }}
        options={pools}
      />
    </>
  );
};

export default PoolSelectWithPoolTemplates;
