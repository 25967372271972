import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Flex, Typography, theme } from "antd";
import { ExamResultQuestionsItem } from "@/entities/exam/lib/model";
import deepmergeProDescriptionsProps from "@/shared/ant-design-pro-components/descriptions/lib/deepmerge-props";

type ExamsDescriptionsProps = ProDescriptionsProps<
  ExamResultQuestionsItem,
  any
>;

const ExamReplyQuestionsDescriptions: React.FC<ExamsDescriptionsProps> = ({
  ...props
}) => {
  const { token } = theme.useToken();

  const defaultProps: Partial<typeof props> = {
    columns: [
      {
        dataIndex: "text",
        render: (_, { text }) => <Typography.Text>{text}</Typography.Text>,
      },
      {
        title: <Typography.Text strong>Балл</Typography.Text>,
        dataIndex: "points",
      },
      {
        title: <Typography.Text strong>Ваш ответ</Typography.Text>,
        dataIndex: "answers.*.text.join()",
        render: (_, { answers, type, answer }) =>
          type === "sequence" ? (
            answers.map(({ value }) => value).join(", ")
          ) : type === "short_answer" ? (
            answer || "Без ответа"
          ) : type === "single" || type === "multiple" ? (
            answers
              .filter(({ is_replied }) => is_replied)
              .map(({ value }) => value)
              .join(", ")
          ) : type === "matching" ? (
            <Flex vertical>
              {answers.map(({ matched_value, matchable_value }, index) => (
                <Typography.Text key={index}>
                  {matched_value} &ndash; {matchable_value}
                  {index < answers.length - 1 ? ", " : ""}
                </Typography.Text>
              ))}
            </Flex>
          ) : null,
      },
      {
        dataIndex: "is_correct",
        title: <Typography.Text strong>Результат</Typography.Text>,
        valueEnum: {
          true: {
            text: "Верно",
            color: token.colorSuccess,
          },
          false: {
            text: "Неверно",
            color: token.colorError,
          },
        },
      },
    ],
  };

  props = deepmergeProDescriptionsProps(defaultProps, props);

  return <ProDescriptions {...props} />;
};

export default ExamReplyQuestionsDescriptions;
