import React from "react";
import {EditableProTable, EditableProTableProps,} from "@ant-design/pro-components";
import axios from "@/axios";
import {OrionRestIndexResponse} from "@/shared/types/orion-rest.tsx";
import {FeatureFlag} from "@/models";
import styled from "styled-components";
import {message} from "antd";
import {AxiosRequestConfig} from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import useParentHeight from "@/shared/hooks/use-parent-height";

const StyledEditableProTable = styled(EditableProTable<FeatureFlag>)`
  width: 65vw;
  margin: 0 auto;

  .ant-pro-card-body {
    padding: 0;
    margin: 16px;
  }
`;

const Page: React.FC = () => {
  const [error, setError] = React.useState<Error | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editableKeys, setEditableRowKeys] = React.useState<React.Key[]>([]);
  const { parentHeight, ref } = useParentHeight();

  const request: EditableProTableProps<any, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `/api/feature-flags/search`,
      ...axiosConfigAdapter(params, sort, filter),
    };
    return axios
      .request<OrionRestIndexResponse<FeatureFlag>>(config)
      .then((response) => {
        setEditableRowKeys(response.data.data.map((item) => item.id));
        return {
          data: response.data.data,
          success: true,
          total: response.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFeatureFlag = async (record: FeatureFlag) => {
    setLoading(true);
    return axios
      .put(`/api/feature-flags/${record.id}`, record)
      .then((response) => {
        message.success(
          record.is_enabled ? "Флаг успешно включен" : "Флаг успешно выключен",
        );
        return response.data;
      })
      .catch((error) => {
        setError(error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (error) throw error;

  return (
    <div
      ref={ref}
      style={{
        width: "65vw",
        margin: "0 auto",
        height: "100%",
      }}
    >
      <StyledEditableProTable
        loading={loading}
        scroll={{ y: parentHeight - 80}}
        rowKey="id"
        request={request}
        recordCreatorProps={false}
        columns={[
          {
            title: "Ключ",
            dataIndex: "key",
            valueType: "text",
            copyable: true,
            editable: false,
            width: "40%",
            defaultSortOrder: "ascend",
          },
          {
            title: "Описание",
            dataIndex: "description",
            valueType: "text",
            editable: false,
            ellipsis: true,
          },
          {
            title: "Включен",
            dataIndex: "is_enabled",
            valueType: "switch",
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ],
            },
            align: "center",
            width: "10%",
          },
        ]}
        onValuesChange={async (record, dataSource) => {
          return updateFeatureFlag(dataSource);
        }}
        editable={{
          type: "multiple",
          editableKeys,
        }}
      />
    </div>
  );
};

export default Page;
