import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Modal } from "antd";
import MemberForm, { MemberFormProps } from "./member-form";

type MemberUpdateModalFormProps = MemberFormProps & {
  trigger?: React.ReactElement;
};

const MemberUpdateModalForm: React.FC<MemberUpdateModalFormProps> = ({
  rest,
  trigger,
  ...formProps
}) => {
  if (!trigger) {
    trigger = (
      <Button type="primary">
        {rest.type === "create" ? "Создать" : "Редактировать"}
      </Button>
    );
  }
  const [open, setOpen] = React.useState(false);

  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }
  const title =
    rest.type === "create"
      ? "Создание пользователя"
      : "Редактирование пользователя";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  return (
    <>
      {React.cloneElement(trigger, {
        disabled,
        onClick: () => setOpen(true),
      })}
      <Modal
        width={1200}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
        centered
        styles={{
          body: {
            height: "90vh",
            overflowY: "auto",
          },
        }}
      >
        <MemberForm {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default MemberUpdateModalForm;
export type { MemberUpdateModalFormProps };
