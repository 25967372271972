import React from "react";
import {
  Button,
  Dropdown,
  DropDownProps,
  Flex,
  MenuProps,
  message,
  theme,
  Typography,
} from "antd";
import copy from "copy-to-clipboard";
import {
  CustomerServiceOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import useSpace from "@/entities/space/lib/use";
import useSupportChatModal from "@/entities/support/lib/use-support-chat-modal";

type Props = DropDownProps & {
  triggerElement?: JSX.Element;
  isReverseItems?: boolean;
};

const SupportDropdown: React.FC<Props> = ({
  triggerElement,
  isReverseItems,
  ...props
}) => {
  const space = useSpace();
  const { token } = theme.useToken();
  const [supportChatModal, supportChatModalHolder] = useSupportChatModal();

  if (!triggerElement) {
    triggerElement = (
      <Button
        type="text"
        icon={
          <CustomerServiceOutlined style={{ fontSize: token.fontSizeXL }} />
        }
      />
    );
  }

  const items: MenuProps["items"] = [
    ...(space.support_email
      ? [
          {
            label: (
              <Typography.Link>
                <Flex gap={token.paddingXS}>
                  <MailOutlined />
                  {space.support_email}
                </Flex>
              </Typography.Link>
            ),
            key: "email",
            onClick: () => {
              copy(space.support_email);
              message.success(
                "Email-адрес поддержки скопирован в буфер обмена",
              );
            },
          },
        ]
      : []),
    ...(space.support_phone_number
      ? [
          {
            label: (
              <Typography.Link href={`tel:${space.support_phone_number}`}>
                <Flex gap={token.paddingXS}>
                  <PhoneOutlined />
                  {space.support_phone_number}
                </Flex>
              </Typography.Link>
            ),
            key: "phone",
          },
        ]
      : []),
    ...(space.is_support_chats_enabled
      ? [
          {
            label: (
              <Typography.Link>
                <Flex gap={token.paddingXS}>
                  <MessageOutlined />
                  Написать в чат
                </Flex>
              </Typography.Link>
            ),
            key: "support_chat",
            onClick: () => {
              supportChatModal.open();
            },
          },
        ]
      : []),
  ];

  return (
    <>
      {supportChatModalHolder}
      {items.length > 0 && (
        <Dropdown
          trigger={["click"]}
          menu={{ items: isReverseItems ? items.reverse() : items }}
          {...props}
        >
          {triggerElement}
        </Dropdown>
      )}
    </>
  );
};

export default SupportDropdown;
