import React from "react";
import useSWR from "swr";
import axios from "@/axios.ts";
import useMe from "@/entities/me/lib/use.tsx";
import useFeatures from "@/entities/features/lib/use.ts";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ModalFuncProps } from "antd/es/modal/interface";

const STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY =
  "storage-availability-checker-last-check";

const StorageAvailabilityChecker: React.FC = () => {
  const me = useMe();
  const features = useFeatures();
  const [modal, modalHolder] = Modal.useModal();
  const navigate = useNavigate();

  const viewEditProxyModal = (props: ModalFuncProps) => {
    modal.confirm({
      okText: "Перейти в настройки",
      onOk: async () => {
        navigate("/profile?tab=settings");
      },
      onCancel: () => {},
      ...props,
    });
  };

  const viewDisableProxyModal = () => {
    viewEditProxyModal({
      title: "Отключить прокси?",
      content:
        "Мы заметили, что ваш браузер может получить доступ к хранилищу файлов без использования прокси.",
    });
  };

  const viewEnableProxyModal = () => {
    viewEditProxyModal({
      title: "Включить прокси?",
      content:
        "Мы заметили, что ваш браузер не может получить доступ к хранилищу файлов. Для решения проблемы может помочь включение прокси в настройках профиля.",
    });
  };

  const { error } = useSWR(
    "storage-availability-checker",
    async () => {
      if (!features.isEnabled("storage_proxy")) {
        return;
      }

      return axios.get("/api/.well-known/storage").then(async (res) => {
        const available = await axios
          .get(res.data.test_file.url)
          .then(() => true)
          .catch(() => false);

        if (available && me.is_storage_proxy_enabled) {
          /** Отображать только раз в час */
          const lastCheck = localStorage.getItem(
            STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY,
          );
          if (!lastCheck || Date.now() - parseInt(lastCheck, 10) > 3600000) {
            localStorage.setItem(
              STORAGE_AVAILABILITY_CHECKER_LAST_CHECK_KEY,
              Date.now().toString(),
            );
            viewDisableProxyModal();
          }
          Sentry.captureMessage(
            "User has storage proxy enabled, but browser can access storage directly",
          );
        } else if (!available && !me.is_storage_proxy_enabled) {
          viewEnableProxyModal();
          Sentry.captureMessage(
            "User has storage proxy disabled, but browser can't access storage directly",
          );
        }
      });
    },
    {
      revalidateOnFocus: false,
    },
  );

  if (error) throw error;

  return <>{modalHolder}</>;
};
export default StorageAvailabilityChecker;
