import { ReactNode } from "react";
import { Member } from "@/models.ts";

export const keycloakStatusValueEnum: Record<
  NonNullable<Member["keycloak_user_status"]>,
  ReactNode
> = {
  to_create: "to_create",
  creating: "creating",
  created: "created",
  to_update: "to_update",
  updating: "updating",
  updated: "updated",
  active: "active",
  to_deactivate: "to_deactivate",
  deactivating: "deactivating",
  deactivated: "deactivated",
  to_delete: "to_delete",
  deleting: "deleting",
  deleted: "deleted",
};
