import { useState } from "react";
import PositionLearningRuleModalFormBatch, {
  PositionLearningRuleModalFormBatchProps,
} from "@/entities/position-learning-rule/ui/modal-form";

const usePositionLearningRuleModalForm = () => {
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState<PositionLearningRuleModalFormBatchProps>(
    {} as any,
  );

  const modalHolder = (
    <PositionLearningRuleModalFormBatch
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
      {...props}
      rest={{
        ...props.rest,
        type: "update",
      }}
    />
  );

  return [
    {
      open: (props: PositionLearningRuleModalFormBatchProps) => {
        setProps(props);
        setOpen(true);
      },
    },
    modalHolder,
  ] as const;
};

export default usePositionLearningRuleModalForm;
