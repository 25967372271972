import React from "react";
import { message, Modal } from "antd";
import { Member } from "@/models";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";

type MemberResetPasswordButtonProps = {
  membersIds: Member["id"][];
  onAfterFinish: () => void;
  trigger?: React.ReactElement;
};

const MemberResetPasswordButton: React.FC<MemberResetPasswordButtonProps> = ({
  membersIds,
  onAfterFinish,
  trigger,
}) => {
  if (!trigger) {
    trigger = (
      <Button type="primary" danger>
        Сбросить пароль
      </Button>
    );
  }
  const [modal, modalHolder] = Modal.useModal();

  const onResetPassword = () => {
    return modal.confirm({
      title: "Вы уверены, что хотите сбросить пароль?",
      content: "Пользователи смогут войти в систему с новым паролем.",
      onOk: async () => {
        return await axios
          .post("/api/members/reset-password", {
            resources: membersIds,
          })
          .then((res) => {
            message.success(res.data.message);
            return onAfterFinish();
          })
          .catch((err) => {
            message.error(err.response.data.message ?? err.message);
            console.error(err);
          });
      },
    });
  };

  return (
    <>
      {modalHolder}
      {React.cloneElement(trigger, {
        onClick: onResetPassword,
        disabled: membersIds.length === 0,
      })}
    </>
  );
};

export default MemberResetPasswordButton;
export type { MemberResetPasswordButtonProps };
