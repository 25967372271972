import React, { useState } from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { Course, Member, Pool, PoolParticipant } from "@/models";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import { Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useParentHeight from "@/shared/hooks/use-parent-height";
import ArchivedCourseIcon from "@/entities/course/ui/archived-icon";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";
import DeletedCourseIcon from "@/entities/course/ui/deleted-icon";

type Data = Omit<PoolParticipant, "pool" | "member"> & {
  pool: Omit<Pool, "course"> & {
    course: Course;
  };
  member: Member;
  filters: any[];
  scopes: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const RepeatedEducationTable: React.FC<TableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const { parentHeight, ref } = useParentHeight("table");
  const [total, setTotal] = React.useState<number>();

  const request: ProTableProps<Data, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const { re_education_at, retry_month_count, ...rest } = sort;
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/pool-participants/search",
      ...axiosConfigAdapter(params, rest, filter),
    };

    if (re_education_at) {
      config.data.scopes.push({
        name: "orderByReEducationAt",
        parameters: re_education_at === "ascend" ? ["ASC"] : ["DESC"],
      });
    }

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    if (params.scopes) {
      config.data.scopes.push(...params.scopes);
    }

    config.data.filters.push(
      {
        field: "ended_at",
        operator: "!=",
        value: null,
      },
      {
        type: "and",
        nested: [
          {
            field: "pool.course.status",
            operator: "=",
            value: "active",
            type: "or",
          },
          {
            field: "pool.course.status",
            operator: "=",
            value: "archived",
            type: "or",
          },
        ],
      },
    );

    config.data.scopes.push({
      name: "withReEducationAt",
    });

    config.data.scopes.push({
      name: "withoutNullReEducationAt",
    });

    if (retry_month_count) {
      config.data.scopes.push({
        name: "withRetryMonthCount",
        parameters: retry_month_count === "ascend" ? ["ASC"] : ["DESC"],
      });
    } else {
      config.data.scopes.push({
        name: "withRetryMonthCount",
      });
    }

    config.data.includes.push(
      { relation: "member" },
      { relation: "pool" },
      { relation: "pool.course" },
      { relation: "member.positions.position" },
    );

    return axios
      .request<OrionRestIndexResponse<Data>>(config)
      .then((res) => {
        setTotal(res.data.meta.total);
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    toolBarRender: false,
    request,
    pagination: {
      defaultPageSize: 20,
    },
    style: { width: "100%" },
    size: "small",
    scroll: {
      y: parentHeight + (total ? 0 : 40) - 187,
    },
    columns: [
      {
        title: "Курс",
        dataIndex: ["pool", "course", "id"],
        renderText: (_, record) => {
          return (
            <>
              {record.pool.course.name}
              {record.pool.course.status === "archived" && (
                <ArchivedCourseIcon />
              )}
              {record.pool.course.status === "deleted" && <DeletedCourseIcon />}
            </>
          );
        },
      },

      {
        title: "Поток",
        dataIndex: ["pool", "id"],
        renderText: (_, record) => {
          return <>{record.pool.name}</>;
        },
      },
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        filters: true,
        filterMode: "search",
        sorter: true,
      },
      {
        title: "Фактическая дата завершения",
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        width: 200,
      },
      {
        title: t("Должность"),
        dataIndex: ["member", "positions", "position_id"],
        renderText: (_, { member: { positions } }) => {
          if (positions && positions.length === 0) {
            return "-";
          }

          const positionNames = positions?.map(({ position }) => {
            const name = position?.name;
            // @ts-ignore
            const treeNodeName = getTreeNodeNames(position?.tree_node);

            return name
              ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
              : "-";
          });

          return (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={positionNames?.join(";\n")}
            >
              <Typography.Text ellipsis>
                {positionNames?.join("; ")}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: "Дата прохождения повторного обучения",
        dataIndex: "re_education_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        width: 200,
      },
    ],
  };

  if (error) throw error;

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<Data>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </div>
  );
};

export default RepeatedEducationTable;
