import React, { useState } from "react";
import { Member } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { Tooltip, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { useTranslation } from "react-i18next";
import useParentHeight from "@/shared/hooks/use-parent-height";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";

type Data = Member & {
  filters?: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const LatestAddedMembersTable: React.FC<TableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const { parentHeight, ref } = useParentHeight("table");

  const [total, setTotal] = React.useState<number>();

  const request: ProTableProps<Member, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/members/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    config.data.includes.push(
      { relation: "positions" },
      { relation: "positions.position" },
      { relation: "positions.position.tree_node.reverse_tree.is_an" },
    );

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    return axios
      .request<OrionRestIndexResponse<Member>>(config)
      .then((res) => {
        setTotal(res.data.meta.total);
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    options: false,
    toolBarRender: false,
    pagination: {
      defaultPageSize: 10,
    },
    scroll: {
      y: parentHeight + (total ? 0 : 40) - 165,
    },
    request: request,
    style: { width: "100%" },
    size: "small",
    columns: [
      {
        title: "ФИО",
        dataIndex: "full_name",
        sorter: true,
      },
      {
        title: t("Должность"),
        dataIndex: ["positions", "position_id"],
        renderText: (_, { positions }) => {
          if (positions && positions.length === 0) {
            return "-";
          }

          const positionNames = positions?.map(({ position }) => {
            const name = position?.name;
            // @ts-ignore
            const treeNodeName = getTreeNodeNames(position?.tree_node);

            return name
              ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
              : "-";
          });

          return (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={positionNames?.join(";\n")}
            >
              <Typography.Text ellipsis>
                {positionNames?.join("; ")}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: "Дата добавления",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  if (error) throw error;

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<Member>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </div>
  );
};

export default LatestAddedMembersTable;
