import React from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { Flex, theme, Typography } from "antd";
import { ExamResultQuestionsItem } from "@/entities/exam/lib/model";

type ExamsTableProps = ProTableProps<ExamResultQuestionsItem, any>;

const ExamReplyQuestionsTable: React.FC<ExamsTableProps> = ({ ...props }) => {
  const { token } = theme.useToken();

  const defaultProps: Partial<typeof props> = {
    // rowKey: "id", // TODO: What to use as rowKey?
    columns: [
      // Вопрос
      {
        title: "Вопрос",
        dataIndex: "text",
      },
      // Балл
      {
        title: "Балл",
        dataIndex: "points",
      },
      // Ваш ответ
      {
        title: "Ответ",
        dataIndex: "answers.*.text.join()",
        render: (_, { answers, type, answer }) =>
          type === "sequence" ? (
            answers.map(({ value }) => value).join(", ")
          ) : type === "short_answer" ? (
            answer || "Без ответа"
          ) : type === "single" || type === "multiple" ? (
            answers
              .filter(({ is_replied }) => is_replied)
              .map(({ value }) => value)
              .join(", ") || "Без ответа"
          ) : type === "matching" ? (
            <Flex vertical>
              {answers.map(({ matched_value, matchable_value }, index) => (
                <Typography.Text key={index}>
                  {matched_value} &ndash; {matchable_value || "Без ответа"}
                  {index < answers.length - 1 ? ", " : ""}
                </Typography.Text>
              ))}
            </Flex>
          ) : null,
      },
      {
        title: "Ответ",
        dataIndex: "answer",
      },
      // Верно/Неверно
      {
        title: "Верно/неверно",
        dataIndex: "is_correct",
        // valueType: "checkbox",
        valueEnum: {
          true: { text: "Верно", color: token.colorSuccess },
          false: { text: "Неверно", color: token.colorError },
        },
        width: 100,
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable {...props} />;
};

export default ExamReplyQuestionsTable;
export type { ExamsTableProps };
