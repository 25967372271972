import React, {
  Children,
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from "react";
import { ProForm } from "@ant-design/pro-components";
import { TabsProps } from "antd/lib/tabs";
import Question from "@/entities/test/lib/question";
import { Button, Space, Spin, Tabs, theme, Typography } from "antd";
import TestQuestionFormFields from "@/entities/test/ui/form-question-fields";
import { TestFormRecord } from "@/entities/test/ui/form";
import { ButtonProps } from "antd/es/button";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import {
  DndContext,
  DndContextProps,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";

const TabStyled = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 0 !important;
  }
  .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  "data-node-key": string;
}

const DraggableTabNode = ({ className, ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1 }),
    transition,
    cursor: "move",
  };

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

function generateTemplateQuestion(): Question {
  return {
    uuid: uuidv4(),
    type: "single",
    text: "Вопрос?",
    points: 1,
    answers: [
      { uuid: uuidv4(), is_correct: true, value: "Ответ 1" },
      { uuid: uuidv4(), is_correct: false, value: "Ответ 2" },
    ],
  };
}

type QuestionsMenuProps = {
  children: React.ReactNode;
  isSubmitButtonLoading: boolean;
  toggleIsSubmitButtonLoading: () => void;
};

const FormQuestionsMenu: React.FC<QuestionsMenuProps> = ({
  children,
  isSubmitButtonLoading,
  toggleIsSubmitButtonLoading,
}) => {
  const form = ProForm.useFormInstance<TestFormRecord>();
  const questions = ProForm.useWatch("questions", form);
  const deferredQuestions = useDeferredValue(questions);

  const {
    token: { colorPrimaryText, fontWeightStrong, fontSizeSM, marginMD },
  } = theme.useToken();

  const [activeTab, setActiveTab] = useState("settings");

  const [_tabItems, setTabItems] = useState<Required<TabsProps>["items"]>([
    {
      key: "loading",
      label: <Spin />,
    },
  ]);
  const tabItems = useDeferredValue(_tabItems);

  const TabsItemChildrenWrapper: React.FC<{ children: React.ReactNode }> =
    useCallback(({ children }) => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {children}
        </div>
      );
    }, []);

  const questionBuilder = useCallback(
    (
      question: TestFormRecord["questions"][number],
      index: number,
      hasValidationErrors: boolean,
    ) => {
      return {
        key: question.uuid,
        label: (
          <Space
            direction={"vertical"}
            size={0}
            align={"start"}
            style={{
              whiteSpace: "normal",
            }}
          >
            <Typography.Text
              type={hasValidationErrors ? "danger" : undefined}
              style={{ margin: 0, fontSize: fontSizeSM }}
              strong
              delete={question.is_deleted}
            >
              Вопрос №{index + 1}
            </Typography.Text>
            <Typography.Paragraph
              delete={question.is_deleted}
              style={{
                margin: 0,
                textAlign: "start",
              }}
              ellipsis={{ rows: 2 }}
            >
              {question.text}
            </Typography.Paragraph>
          </Space>
        ),
        children: (
          <TabsItemChildrenWrapper>
            <TestQuestionFormFields
              currentIndex={index}
              onAfterFinish={updateTabs}
              isSubmitButtonLoading={isSubmitButtonLoading}
              toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
            />
          </TabsItemChildrenWrapper>
        ),
      };
    },
    [isSubmitButtonLoading],
  );

  const updateTabs = useCallback(async () => {
    const fieldsErrors = form.getFieldsError();
    const isQuestionHasErrors = (index: number) => {
      return (
        fieldsErrors.find((error) => {
          return (
            error.name &&
            error.name[0] === "questions" &&
            error.name[1] === index &&
            error.errors.length > 0
          );
        }) !== undefined
      );
    };

    const questions: TestFormRecord["questions"] =
      form.getFieldValue("questions") ?? [];

    setTabItems(() => {
      if (questions === undefined) {
        throw new Error("Questions is undefined");
      }

      const isSettingsHasErrors =
        fieldsErrors.find((error) => {
          return (
            error.name && error.name.length === 1 && error.errors.length > 0
          );
        }) !== undefined;

      return [
        {
          key: "settings",
          label: (
            <Typography.Text
              type={isSettingsHasErrors ? "danger" : undefined}
              style={{
                fontWeight: fontWeightStrong,
                color: colorPrimaryText,
              }}
            >
              Настройки теста
            </Typography.Text>
          ),
          children: (
            <TabsItemChildrenWrapper>
              {Children.map(children, (child) =>
                //@ts-ignore
                React.cloneElement(child, {
                  onAfterImportQuestions: () => updateTabs(),
                }),
              )}
            </TabsItemChildrenWrapper>
          ),
        },
        ...questions.map((question, index) => {
          return questionBuilder(question, index, isQuestionHasErrors(index));
        }),
      ];
    });
  }, [questionBuilder]);

  const debouncedUpdateTabs = useCallback(debounce(updateTabs, 300), [
    questionBuilder,
    form,
  ]);

  useEffect(() => {
    debouncedUpdateTabs();
  }, [deferredQuestions, isSubmitButtonLoading]);

  const addQuestion = async () => {
    const questions = form.getFieldValue(["questions"]);

    const newIndex = questions.length;
    const newQuestion = generateTemplateQuestion();
    newQuestion.text = `Вопрос №${newIndex + 1}`;

    const newQuestions = [...questions, newQuestion];

    form.setFieldsValue({ questions: newQuestions });
    await updateTabs();

    setActiveTab(newQuestion.uuid);
  };

  const TabsExtraContentButton: React.FC<ButtonProps> = ({
    children,
    ...props
  }) => {
    return (
      <Button style={{ width: "180px" }} {...props}>
        {children}
      </Button>
    );
  };

  const TabsExtraContent: React.FC = () => {
    return (
      <Space
        direction={"vertical"}
        align={"center"}
        style={{ marginTop: marginMD }}
      >
        <TabsExtraContentButton type="primary" onClick={() => addQuestion()}>
          Добавить вопрос
        </TabsExtraContentButton>
      </Space>
    );
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd: DndContextProps["onDragEnd"] = async ({
    active,
    over,
  }: DragEndEvent) => {
    if (!active || !over) return;
    if (active.id !== over.id) {
      if (typeof active.id !== "string" || typeof over?.id !== "string") {
        throw new Error("Invalid id type");
      }
      const activeUUID = active.id;
      const overUUID = over.id;
      if (!activeUUID || !overUUID) return;

      const questions: Question[] = form.getFieldValue(["questions"]);
      const getIndexByUUID = (uuid: string): number => {
        return questions.findIndex((item) => item.uuid === uuid);
      };

      const activeIndex = getIndexByUUID(activeUUID);
      const overIndex = getIndexByUUID(overUUID);

      if (activeIndex === -1 || overIndex === -1) return;

      const newQuestions = arrayMove(questions, activeIndex, overIndex);

      form.setFieldsValue({ questions: newQuestions });

      const newTabs = arrayMove(tabItems, activeIndex + 1, overIndex + 1);
      setTabItems(newTabs);
    }
  };

  const renderTabBar: TabsProps["renderTabBar"] = (
    tabBarProps,
    DefaultTabBar,
  ) => {
    return (
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext
          items={tabItems.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <DefaultTabBar {...tabBarProps}>
            {(node) => (
              <>
                {node.key === "settings" ? (
                  node
                ) : (
                  <DraggableTabNode {...node.props} key={node.key}>
                    {node}
                  </DraggableTabNode>
                )}
              </>
            )}
          </DefaultTabBar>
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <TabStyled
      size="small"
      tabBarGutter={0}
      tabBarExtraContent={<TabsExtraContent />}
      tabBarStyle={{ width: "200px" }}
      tabPosition={"left"}
      style={{ height: "100%" }}
      items={tabItems}
      activeKey={activeTab}
      onChange={(newTab) => {
        setActiveTab(newTab);
      }}
      renderTabBar={renderTabBar}
    />
  );
};

export default FormQuestionsMenu;
export type { QuestionsMenuProps };
