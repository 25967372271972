import React, { useState } from "react";
import { PositionLearningRule } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormProps,
  ProFormSwitch,
} from "@ant-design/pro-components";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { deepmerge } from "deepmerge-ts";
import { Col, Flex, message, Row, Space, theme } from "antd";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { FieldData } from "rc-field-form/es/interface";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import { useTranslation } from "react-i18next";
import CourseSelectWithExternal from "@/entities/course/ui/select-with-external.tsx";
import PoolSelectWithPoolTemplates from "@/entities/pool/ui/select-with-pool-templates";
import useMe from "@/entities/me/lib/use";

type CourseList = (Partial<PositionLearningRule> & {
  course_type_with_id: string;
  pool_type_with_id: string | null;
})[];

type Record = {
  position_id: PositionLearningRule["position_id"];
  course_list: CourseList;
};

type PositionLearningRuleFormBatchProps = ProFormProps<Record> & {
  rest: {
    type: "update" | "create";
    recordKey?: number;
    onAfterFinish: () => void;
  };
};

const PositionLearningRuleFormBatch: React.FC<
  PositionLearningRuleFormBatchProps
> = ({ rest, ...props }) => {
  const [form] = ProForm.useForm<Record>();
  const courseList = ProForm.useWatch("course_list", form);
  const { t } = useTranslation();
  const member = useMe();

  const [toRemovePositionLearningRuleIds, setToRemovePositionLearningRuleIds] =
    useState<number[]>([]);

  const { token } = theme.useToken();

  const checkDublicateCourse = (courses: CourseList) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;
    courses.forEach((item, index) => {
      if (!item.course_type_with_id) return;
      if (item.course_type_with_id in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.course_type_with_id] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_list", index, "course_type_with_id"],
            errors: ["Курс дублируется, выберите другой курс или удалите поле"],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const getPositionLearningRecordsByFilter = async (filter: {
    [key: string]: any;
  }) => {
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/position-learning-rules/search",
      ...axiosConfigAdapter(
        {
          pageSize: 20,
        },
        {},
        filter,
      ),
    };

    return await axios
      .request<OrionRestIndexResponse<PositionLearningRule>>(axiosConfig)
      .then(({ data }) => data.data)
      .catch((error) => {
        message.error(error.response.data.message);
        throw error;
      });
  };

  const defaultProps: Partial<typeof props> = {
    submitter: {
      searchConfig: {
        submitText: "Сохранить",
        resetText: "Отменить",
      },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  defaultProps.onFinish = async (values) => {
    const { course_list: courseList, position_id } = values;

    /** Check duplicate courses */
    if (checkDublicateCourse(courseList)) {
      return;
    }

    /** Create and update position learning rule records */

    const resourcesToCreate: PositionLearningRule[] = [];
    const resourcesToUpdate: { [key: string]: PositionLearningRule } = {};

    courseList.forEach((courseList) => {
      const [course_type, course_id] =
        courseList.course_type_with_id.split("#");
      const [pool_type, pool_id] =
        courseList.pool_type_with_id?.split("#") ?? [];
      const { learning_expiration_months_count } = courseList;

      const record = {
        position_id,
        course_id: Number(course_id),
        course_type: course_type as "external_course" | "course",
        pool_id: pool_id ? Number(pool_id) : null,
        pool_type: pool_type as "pool" | "pool_template" | null,
        learning_expiration_months_count:
          learning_expiration_months_count ?? null,
        is_learning_auto_assigment_enabled:
          courseList.is_learning_auto_assigment_enabled ?? false,
      } as PositionLearningRule;

      if (courseList.id) {
        resourcesToUpdate[courseList.id] = record;
      } else {
        resourcesToCreate.push(record);
      }
    });

    /** Create position learning rule records */

    if (resourcesToCreate.length > 0) {
      await axios
        .post("/api/position-learning-rules/batch", {
          resources: resourcesToCreate,
        })
        .then((res) => {
          message.success("Правила обучения успешно созданы");
        })
        .catch((error) => {
          message.error(
            error.response.data.message ??
              "Ошибка при создании правил обучения должности",
          );
          return Promise.reject();
        });
    }

    /** Update position learning rule records */

    if (Object.keys(resourcesToUpdate).length > 0) {
      await axios
        .patch("/api/position-learning-rules/batch", {
          resources: resourcesToUpdate,
        })
        .then((res) => {
          message.success("Правила обучения успешно обновлены");
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response.data.message ??
              "Ошибка при обновлении правил обучения",
          );
          return Promise.reject();
        });
    }

    /** Delete position learning rule records */

    if (toRemovePositionLearningRuleIds.length > 0) {
      await axios
        .delete(`/api/position-learning-rules/batch`, {
          data: { resources: toRemovePositionLearningRuleIds },
        })
        .then(() => {
          message.success("Правила обучения успешно удалены");
          setToRemovePositionLearningRuleIds([]);
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response.data.message ??
              "Ошибка при удалении правил обучения",
          );
          return Promise.reject();
        });
    }

    rest.onAfterFinish();

    return Promise.resolve(true);
  };

  /** REST Type Update request */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      const filter = {
        position_id: [rest.recordKey],
      };

      const positionLearningRecord =
        (await getPositionLearningRecordsByFilter(filter)) ?? [];

      return {
        position_id: positionLearningRecord[0].position_id,
        course_list: positionLearningRecord.map((item) => ({
          course_type_with_id: `${item.course_type}#${item.course_id}`,
          pool_type_with_id: item.pool_id
            ? `${item.pool_type}#${item.pool_id}`
            : null,
          learning_expiration_months_count:
            item.learning_expiration_months_count,
          id: item.id,
          is_learning_auto_assigment_enabled:
            item.is_learning_auto_assigment_enabled,
        })),
      };
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<Record> {...props}>
      <Flex style={{ width: "100%" }} justify="space-between">
        <Row gutter={token.paddingMD} style={{ width: "100%" }}>
          <Col span={24}>
            <OrgStructureTreeSelect
              disabled={rest.type === "update"}
              label={t("Должность")}
              disabledDepartments
              name={"position_id"}
              rules={[{ required: true }]}
              labelWithPath
            />
          </Col>
        </Row>
      </Flex>

      <ProFormList
        style={{ marginTop: token.marginMD }}
        containerStyle={{
          /**         formGroup height
           *               |   max number formGroup no scroll
           *               |        |  add button height
           *               |        |     |
           *               |        |     |
           */
          maxHeight: "calc(87.5px * 5 + 32px)",
          overflow: "auto",
        }}
        alwaysShowItemLabel
        initialValue={
          rest.type === "create"
            ? [{ is_learning_auto_assigment_enabled: false }]
            : undefined
        }
        creatorButtonProps={{
          creatorButtonText: "Добавить еще",
          type: "primary",
          block: false,
          icon: false,
        }}
        copyIconProps={false}
        deleteIconProps={
          member.permissions.includes("position_learning_rule:delete")
            ? {
                tooltipText: "Удалить",
              }
            : false
        }
        onAfterRemove={async (index) => {
          if (courseList && typeof index === "number" && courseList[index].id) {
            setToRemovePositionLearningRuleIds((prevState) => [
              ...prevState,
              courseList[index].id!,
            ]);
          }
        }}
        min={rest.type === "create" ? 1 : 0}
        max={20}
        name="course_list"
        creatorRecord={{
          is_learning_auto_assigment_enabled: false,
        }}
      >
        {(_, index, action) => {
          const courseId = action.getCurrentRowData()?.course_type_with_id;
          const learningExpirationMonthsCount =
            action.getCurrentRowData()?.learning_expiration_months_count;

          const poolTypeWithId = action.getCurrentRowData()?.pool_type_with_id;

          return (
            <Space
              style={{
                marginRight: token.margin,
                alignItems: "flex-start",
                overflow: "hidden",
              }}
              size={token.marginXL}
            >
              <CourseSelectWithExternal
                width={"md"}
                rules={[{ required: true }]}
                listName={["course_list", index, "course_type_with_id"]}
                isActiveFilter
                label="Курс"
                name="course_type_with_id"
                onChange={() => {
                  form.setFieldValue(
                    ["course_list", index, "pool_type_with_id"],
                    null,
                  );
                  form.setFieldValue(
                    [
                      "course_list",
                      index,
                      "is_learning_auto_assigment_enabled",
                    ],
                    false,
                  );
                }}
              />

              <ProFormDigit
                min={1}
                width={"xs"}
                label="Повтор (мес)"
                name="learning_expiration_months_count"
                fieldProps={{
                  onChange: (value) => {
                    if (
                      value &&
                      poolTypeWithId &&
                      poolTypeWithId.split("#")[0] !== "pool_template"
                    ) {
                      form.setFieldValue(
                        ["course_list", index, "pool_type_with_id"],
                        null,
                      );
                      form.setFieldValue(
                        [
                          "course_list",
                          index,
                          "is_learning_auto_assigment_enabled",
                        ],
                        false,
                      );
                    }
                  },
                }}
              />

              <PoolSelectWithPoolTemplates
                listName={["course_list", index, "pool_type_with_id"]}
                width={"md"}
                tooltip={
                  "Потоки недоступны для выбора если указан повтор обучения, только шаблоны"
                }
                courseId={
                  courseId && courseId.split("#")[0] === "course"
                    ? courseId.split("#")[1]
                    : undefined
                }
                disabled={
                  (courseId && courseId.split("#")[0] === "external_course") ||
                  !courseId
                }
                poolDisabled={!!learningExpirationMonthsCount}
                label="Поток"
                name="pool_type_with_id"
                fieldProps={{
                  onChange: (value) => {
                    if (!value) {
                      form.setFieldValue(
                        [
                          "course_list",
                          index,
                          "is_learning_auto_assigment_enabled",
                        ],
                        false,
                      );
                    }
                  },
                }}
              />

              <ProFormSwitch
                disabled={!poolTypeWithId}
                label="Назначать автоматически"
                tooltip="Включение правила обучения для выбранного потока. Нельзя включить, если не выбран поток или выбран внешний курс"
                name="is_learning_auto_assigment_enabled"
              />
            </Space>
          );
        }}
      </ProFormList>
    </ProForm>
  );
};

export default PositionLearningRuleFormBatch;
export type { PositionLearningRuleFormBatchProps };
