import React from "react";
import {EditableProTable, EditableProTableProps,} from "@ant-design/pro-components";
import {AxiosRequestConfig} from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import axios from "@/axios";
import {OrionRestIndexResponse} from "@/shared/types/orion-rest.tsx";
import {FeatureFlag, FeatureFlagsPackage as BaseFeatureFlagsPackage, FeatureFlagsPackageItem,} from "@/models.ts";
import styled from "styled-components";
import {message, Space, theme, Typography} from "antd";
import {RowEditableConfig} from "@ant-design/pro-utils/es/useEditableArray";
import {ActionType} from "@ant-design/pro-table/es/typing";
import useParentHeight from "@/shared/hooks/use-parent-height";

type FeatureFlagsPackage = Omit<BaseFeatureFlagsPackage, "id" | "items"> & {
  id: BaseFeatureFlagsPackage["id"] | "-";
  items: NonNullable<BaseFeatureFlagsPackage["items"]>;
};

const FeatureFlagsPackagesTable = styled(EditableProTable<FeatureFlagsPackage>)`
  width: 100%;
  margin: 0 auto;

  .ant-pro-card-body {
    padding: 0;
  }

  .ant-btn-dashed {
    margin-left: 16px !important;
    width: calc(100% - 32px) !important;
  }
`;

const FeatureFlagsPackageItemsTable = styled(
  EditableProTable<FeatureFlagsPackageItem>,
)`
  .ant-table {
    margin: 0 !important;
  }
`;

const Page: React.FC = () => {
  const [error, setError] = React.useState<Error | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const actionRef = React.useRef<ActionType>();
  const { token } = theme.useToken();
  const { parentHeight, ref } = useParentHeight();

  const request: EditableProTableProps<any, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `/api/feature-flags-packages/search`,
      ...axiosConfigAdapter(params, sort, filter),
    };

    config.data.sort.push({ field: "id", order: "asc" });

    config.data.includes.push({ relation: "items" });
    config.data.includes.push({ relation: "items.feature_flag" });

    return axios
      .request<OrionRestIndexResponse<FeatureFlag>>(config)
      .then((response) => {
        return {
          data: response.data.data,
          success: true,
          total: response.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFeatureFlagsPackageItem = async (
    record: FeatureFlagsPackageItem,
  ) => {
    setLoading(true);
    return axios
      .put(`/api/feature-flags-package-items/${record.id}`, record)
      .then(() => {
        message.success(
          record.is_enabled
            ? "Флаг пакета успешно включен"
            : "Флаг пакета успешно выключен",
        );
        return true;
      })
      .catch((error) => {
        setError(error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSave: RowEditableConfig<FeatureFlagsPackage>["onSave"] = async (
    _,
    record,
  ) => {
    setLoading(true);

    const config: AxiosRequestConfig =
      record.id === "-"
        ? { method: "POST", url: `/api/feature-flags-packages`, data: record }
        : {
            method: "PUT",
            url: `/api/feature-flags-packages/${record.id}`,
            data: record,
          };

    return axios
      .request(config)
      .then(() => {
        message.success(
          record.id === "-" ? "Пакет успешно создан" : "Пакет успешно обновлен",
        );
        actionRef.current?.reload();
        return true;
      })
      .catch((error) => {
        if (error.response?.status === 422 || error.response?.status === 400) {
          message.error(error.response.data.message);
        } else {
          setError(error);
        }

        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (error) throw error;

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <FeatureFlagsPackagesTable
        actionRef={actionRef}
        scroll={{ y: parentHeight - 128}}
        rowKey={"id"}
        loading={loading}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            editable: false,
            width: "5%",
          },
          {
            title: "Название",
            dataIndex: "name",
          },
          {
            title: "Статус",
            dataIndex: "status",
            valueType: "select",
            valueEnum: {
              draft: { text: "Черновик", status: "default" },
              active: { text: "Активный", status: "processing" },
              archived: { text: "Архивный", status: "error" },
            },
            align: "center",
            width: "15%",
          },
          {
            title: "Стандартный",
            dataIndex: "is_default",
            valueType: "switch",
            render: (_, record) => {
              return record.is_default ? "Да" : "Нет";
            },
            formItemProps: {
              rules: [{ required: true, message: "Обязательное поле" }],
            },
            align: "center",
            width: "10%",
          },
          {
            title: "Действия",
            valueType: "option",
            render: (_, record, __, action) => {
              return (
                <a
                  onClick={() => {
                    action?.startEditable?.(record.id);
                  }}
                >
                  Редактировать
                </a>
              );
            },
            align: "center",
            width: "15%",
          },
        ]}
        editable={{
          type: "multiple",
          onSave: onSave,
          onDelete: () => {
            message.error("Удаление недоступно");
            throw new Error("Удаление недоступно");
          },
          actionRender: (_, __, { save, cancel }) => {
            return [save, cancel];
          },
          onlyAddOneLineAlertMessage:
            "Сохраните текущую запись, прежде чем добавить новую",
          onlyOneLineEditorAlertMessage:
            "Редактирование одной записи в одно время",
          formProps: {
            preserve: false,
          },
        }}
        recordCreatorProps={{
          position: "bottom",
          record: {
            id: "-",
            name: "Новый пакет",
            status: "draft",
            is_default: false,
            items: [],
            created_at: null,
            updated_at: null,
          },
          creatorButtonText: "Добавить пакет",
        }}
        request={request}
        search={false}
        options={false}
        expandable={{
          rowExpandable: (record) => record.id !== "-",
          expandedRowRender: (record) => {
            const editableKeys = record.items.map((item) => item.id);

            return (
              <Space
                style={{
                  width: "calc(100% - 80px)",
                  padding: "8px",
                  margin: "0 40px",
                  border: `1px solid ${token.colorBorder}`,
                  borderRadius: "8px",
                  background: token.colorBgContainer,
                }}
                direction={"vertical"}
              >
                <Typography.Text style={{ marginTop: 0 }} strong>
                  Флаги функциональности пакета
                </Typography.Text>
                <FeatureFlagsPackageItemsTable
                  rowKey={"id"}
                  recordCreatorProps={false}
                  search={false}
                  toolBarRender={false}
                  request={async () => {
                    return { data: record.items };
                  }}
                  columns={[
                    {
                      title: "Ключ",
                      dataIndex: ["feature_flag", "key"],
                      valueType: "text",
                      copyable: true,
                      editable: false,
                      defaultSortOrder: "ascend",
                      width: "40%",
                    },
                    {
                      title: "Описание",
                      dataIndex: ["feature_flag", "description"],
                      valueType: "text",
                      editable: false,
                      ellipsis: true,
                    },
                    {
                      title: "Глобально включен",
                      dataIndex: ["feature_flag", "is_enabled"],
                      valueType: "switch",
                      render: (_, record) => {
                        if (!record.feature_flag) return "-";

                        return record.feature_flag.is_enabled ? "Да" : "Нет";
                      },
                      editable: false,
                      align: "center",
                      width: "10%",
                    },
                    {
                      title: "Включен",
                      dataIndex: "is_enabled",
                      valueType: "switch",
                      formItemProps: {
                        rules: [
                          {
                            required: true,
                            message: "Обязательное поле",
                          },
                        ],
                      },
                      align: "center",
                      width: "10%",
                    },
                  ]}
                  onValuesChange={async (_, dataSource) => {
                    return updateFeatureFlagsPackageItem(dataSource);
                  }}
                  editable={{
                    type: "multiple",
                    editableKeys,
                  }}
                />
              </Space>
            );
          },
        }}
      />
    </div>
  );
};

export default Page;
