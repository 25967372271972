import React from "react";
import { Group, GroupParticipant } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { Tooltip, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import GroupParticipantForm from "@/entities/group-participant/ui/form-batch";
import GroupParticipantDeleteButton from "@/entities/group-participant/ui/delete-button";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { useTranslation } from "react-i18next";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import useFeatures from "@/entities/features/lib/use";
import { createPositionFilter } from "@/entities/member/lib/create-position-filter";

type Data = GroupParticipant;

type Params = Partial<GroupParticipant>;

type TableProps = BaseProTableProps<Data, Params>;

type GroupParticipantTableProps = TableProps & {
  groupId: Group["id"];
};

const GroupParticipantTable: React.FC<GroupParticipantTableProps> = ({
  groupId,
  ...props
}) => {
  const member = useMe();
  const { t } = useTranslation();
  const features = useFeatures();

  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();

  const { parentHeight, ref } = useParentHeight("table");

  const request: TableProps["request"] = async (
    params,
    sort,
    filter: Record<string, (string | number | null)[] | null>,
  ) => {
    const { ["member,positions,position_id"]: positionIds, ...filters } =
      filter;

    const config = {
      method: "POST",
      url: "/api/group-participants/search",
      ...axiosConfigAdapter(params, sort, filters),
    };

    if (positionIds) {
      config.data.filters.push(
        createPositionFilter(positionIds, "member.positions.position_id"),
      );
    }

    config.data.filters.push({
      field: "group_id",
      operator: "=",
      value: groupId,
    });

    config.data.includes.push(
      { relation: "member" },
      { relation: "member.positions.position" },
      {
        relation: "member.positions.position.tree_node.reverse_tree.is_an",
      },
    );

    const data = await axios
      .request<OrionRestIndexResponse<GroupParticipant>>(config)
      .then((res) => res.data);

    setTotal(data.meta.total);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  /** Create form */

  const defaultProps: TableProps = {
    rowKey: "id",
    request,
    style: {
      height: "100%",
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
    },
    scroll: {
      y: parentHeight + (total ? 0 : 40) - (someSelected ? 225 : 165),
    },
    options: false,
    toolBarRender: (action, { selectedRowKeys, selectedRows }) => {
      if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
      if (!selectedRows) throw new Error("selectedRows is undefined");
      if (!action) throw new Error("action is undefined");

      const membersIds = selectedRows.map((el) => Number(el.member_id));

      return [
        member.permissions.includes("member:update") && (
          <GroupParticipantDeleteButton
            key="delete"
            recordKeys={selectedRowKeys.map(Number)}
            onAfterDelete={() => {
              action.reloadAndRest?.();
              action.clearSelected?.();
            }}
          />
        ),
        member.permissions.includes("member:update") && (
          <GroupParticipantForm
            key="addMembers"
            modal
            groupId={[groupId]}
            onAfterAdd={() => action.reload()}
          />
        ),
        member.permissions.includes("pool_participant:create") && (
          <MemberEducationPurposeModalForm
            key="education-purpose"
            membersIds={membersIds}
          />
        ),
      ];
    },
    pagination: { showSizeChanger: true },
    rowSelection: {
      preserveSelectedRowKeys: true,
      onChange: (selectedRowKeys) => {
        setSomeSelected(selectedRowKeys.length > 0);
      },
    },
    columns: [
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        sorter: true,
      },
      {
        title: t("Должность"),
        order: features.isEnabled("restrict_accesses") ? 1 : undefined,
        dataIndex: ["member", "positions", "position_id"],
        renderText: (_, { member: { positions } }) => {
          if (positions && positions.length === 0) {
            return "-";
          }

          const positionNames = positions?.map(({ position }) => {
            const name = position?.name;
            // @ts-ignore
            const treeNodeName = getTreeNodeNames(position?.tree_node);

            return name
              ? `${treeNodeName ? treeNodeName + ", " : ""}${name}`
              : "-";
          });

          return (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={positionNames?.join(";\n")}
            >
              <Typography.Text ellipsis>
                {positionNames?.join("; ")}
              </Typography.Text>
            </Tooltip>
          );
        },
        renderFormItem(_, config) {
          return (
            <OrgStructureTreeSelect
              searchUser
              labelWithPath
              fieldProps={{ multiple: true }}
              {...config}
            />
          );
        },
      },
      {
        title: () => "Дата добавления",

        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          tooltip: false,
          name: ["range", "created_at"],
        },
        renderFormItem(_, config, form) {
          return (
            <DateTimeRangePickerWithTimeControls
              name={["range", "created_at"]}
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<GroupParticipant>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </div>
  );
};

export default GroupParticipantTable;
export type { GroupParticipantTableProps };
