import { uniq } from "lodash";

export const createPositionFilter = (
  positionIds: (number | string | null)[],
  field: string,
): Record<string, any> => {
  const positionsIdsFlat = positionIds.flatMap((v) => {
    if (typeof v === "string") {
      return v.split(",").reduce((acc, id) => {
        if (!isNaN(Number(id))) acc.push(Number(id));
        return acc;
      }, [] as number[]);
    }
    return [v];
  });

  const uniquePositionsIds = uniq(positionsIdsFlat);

  return {
    type: "and",
    nested: uniquePositionsIds.map((id) => ({
      type: "or",
      field,
      operator: "=",
      value: id,
    })),
  };
};
