import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Grid,
  message,
  Modal,
  Row,
  Spin,
  Statistic,
  Tabs,
  TabsProps,
  theme,
  Typography,
} from "antd";
import { ButtonProps } from "antd/es/button";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ProForm } from "@ant-design/pro-components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "@/axios";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { Exam } from "@/entities/exam/lib/model";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useQueryState from "@/shared/hooks/use-query-state";
import Question from "./question";

const INITIAL_QUESTION_INDEX = 0;
const INITIAL_QUESTION_KEY = `${INITIAL_QUESTION_INDEX}`;

const Page: React.FC = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { pool_participant_id, exam_id } = useParams();
  const [form] = ProForm.useForm<Exam>();
  const [deadlineCountdown, setDeadlineCountdown] = useState<
    number | undefined
  >();
  const screens = Grid.useBreakpoint();
  const [navigationButtonsModal, setNavigationButtonsModal] = useState(false);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [exam, setExam] = useState<Exam | null>(null);

  useEffect(() => {
    axios
      .get<OrionRestShowResponse<Exam>>(`/api/exams/${exam_id}`)
      .then((res) => res.data.data)
      .then(async (exam) => {
        if (exam.status === "not_started") {
          exam = await axios
            .put<OrionRestUpdateResponse<Exam>>(`/api/exams/${exam_id}`, {
              status: "launched",
            })
            .then((res) => res.data.data);
          message.success("Тест не был начат, поэтому мы начали его за вас");
        }

        if (!exam.reply) {
          exam.reply = exam.test.questions.map((question) => {
            if (question.type === "single" || question.type === "multiple") {
              return {
                ...question,
                answers: question.answers.map((answer) => ({
                  ...answer,
                  is_correct: false,
                })),
              };
            } else if (question.type === "sequence") {
              return question;
            } else if (question.type === "short_answer") {
              return {
                ...question,
                answer: "",
              };
            }
            const answers: any[] = [];
            question.matched?.forEach((matched) => {
              answers.push({
                matched_value: matched.value,
                matched_uuid: matched.uuid,
                matchable_uuid: null,
              });
            });
            return { ...question, answers: answers };
          });
        } else {
          exam.reply = exam.reply.map((question) => {
            if (question.type === "matching") {
              if (question.answers?.length === 0) {
                const answers: any[] = [];
                const questiona = exam.test.questions.find(
                  (q) => q.uuid === question.uuid,
                );
                questiona?.matched?.forEach((matched) => {
                  answers.push({
                    matched_value: matched.value,
                    matched_uuid: matched.uuid,
                    matchable_uuid: null,
                  });
                });
                return {
                  ...question,
                  matchable: questiona?.matchable,
                  answers,
                };
              }
              const answers = question?.answers?.map((answer) => ({
                ...answer,
                matched_value: exam.test.questions
                  .find((q) => q.uuid === question.uuid)
                  ?.matched?.find((m) => m.uuid === answer.matched_uuid)?.value,
              }));
              return {
                ...question,
                answers,
                matchable: exam.test.questions.find(
                  (q) => q.uuid === question.uuid,
                )?.matchable,
              };
            }
            return question;
          });
        }

        if (exam.deadline_in) {
          setDeadlineCountdown(Date.now() + exam.deadline_in * 1000);
        }

        setExam(exam);
        form.setFieldsValue(exam);
        const activeQuestionKey = searchParams.get("active_question_key");
        if (!activeQuestionKey) setActiveQuestionKey(INITIAL_QUESTION_KEY);
        else setActiveQuestionKey(activeQuestionKey);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const [questionsTabsItems, setQuestionsTabsItems] = useState<
    Required<TabsProps>["items"]
  >([]);

  useEffect(() => {
    if (exam) {
      const questionsTabsItems = exam.reply.map((question, index) => ({
        key: `${index}`,
        label: `${index + 1}`,
        children: (
          <Question
            examId={Number(exam_id)}
            question={question}
            questionIndex={index}
          />
        ),
      }));
      setQuestionsTabsItems(questionsTabsItems);
    }
  }, [exam, form]);

  const QuestionsNavigationButtons: React.FC<{ closeModal?: boolean }> = ({
    closeModal,
  }) => (
    <Flex wrap="wrap" gap="small" justify="start">
      {questionsTabsItems.map((item) => {
        let type: ButtonProps["type"] = undefined;
        let primaryColor = token.colorPrimary;

        if (item.key === activeQuestionKey) {
          type = "primary";
        } else {
          const exam: Exam = form.getFieldsValue(true);
          const question = exam.reply[Number(item.key)];

          if (
            question.answers?.some((answer) => answer.is_correct) ||
            (question.type === "short_answer" && question.answer) ||
            (question.type === "matching" &&
              question.answers?.every((answer) => answer.matchable_uuid)) ||
            (question.type === "sequence" && question.is_touched)
          ) {
            type = "primary";
            primaryColor = token.colorSuccess;
          }
        }

        return (
          <ConfigProvider
            key={item.key}
            theme={{
              token: {
                colorPrimary: primaryColor,
              },
            }}
          >
            <Button
              type={type}
              onClick={() => {
                if (closeModal) {
                  setNavigationButtonsModal(false);
                }
                setActiveQuestionKey(item.key);
              }}
              style={{ width: "64px" }}
            >
              {item.label}
            </Button>
          </ConfigProvider>
        );
      })}
    </Flex>
  );

  const [activeQuestionKey, _setActiveQuestionKey] = useQueryState(
    "active_question_key",
    {
      valueType: "string",
    },
  );
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(
    INITIAL_QUESTION_INDEX,
  );
  const [isFirstQuestion, setIsFirstQuestion] = useState(true);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const setActiveQuestionKey: typeof _setActiveQuestionKey = (key) => {
    _setActiveQuestionKey(key);
    setActiveQuestionIndex(Number(key));
    setIsFirstQuestion(Number(key) === 0);
    setIsLastQuestion(Number(key) === questionsTabsItems.length - 1);
  };

  const onNextQuestion = () => {
    setActiveQuestionKey(`${activeQuestionIndex + 1}`);
  };

  const onPrevQuestion = () => {
    setActiveQuestionKey(`${activeQuestionIndex - 1}`);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          if (isFirstQuestion) return;
          return onPrevQuestion();
        case "ArrowRight":
          if (isLastQuestion) return;
          return onNextQuestion();
        default:
          return;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeQuestionIndex]);

  const navigateToEducationPoolParticipant = () => {
    navigate(
      searchParams.get("pool_participant_url") ??
        `/education/${pool_participant_id}`,
    );
  };

  const onTestFinish = async () => {
    await axios.put(`/api/exams/${exam_id}`, {
      status: "to_check",
      reply: form.getFieldsValue(true).reply,
    });
    message.success("Тест завершен");
  };

  const onTestFinishByUser = () => {
    modal.confirm({
      title: "Вы уверены, что хотите завершить выполнение теста?",
      content: "Убедитесь, что вы дали ответы на все вопросы",
      okText: "Продолжить",
      onOk: async () => {
        await onTestFinish();
        navigateToEducationPoolParticipant();
      },
      cancelText: "Назад",
    });
  };

  const [modal, contextHolder] = Modal.useModal();

  const modalDeadlineFinish = () => {
    modal.error({
      title: "Выделенное на выполнение теста время вышло",
      content: "Ваши ответы сохранены",
      okText: "Продолжить",
      onOk: () => {
        navigateToEducationPoolParticipant();
      },
    });
  };

  const onDeadlineFinish = () => {
    onTestFinish();
    modalDeadlineFinish();
  };

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!exam) throw new Error("Exam is not loaded");

  if (exam.deadline_in && exam.deadline_in <= 0) {
    return (
      <>
        {contextHolder}
        {modalDeadlineFinish()}
      </>
    );
  }

  const modalStatusNotLaunched = () => {
    modal.error({
      title: "Прохождение теста невозможно",
      content: "Возможно, он был завершен ранее",
      okText: "Продолжить",
      onOk: () => {
        navigateToEducationPoolParticipant();
      },
    });
  };

  if (exam.status !== "launched") {
    return (
      <>
        {contextHolder}
        {modalStatusNotLaunched()}
      </>
    );
  }

  return (
    <>
      {contextHolder}
      <Modal
        open={navigationButtonsModal}
        onCancel={() => setNavigationButtonsModal(false)}
        footer={false}
        closeIcon={false}
      >
        <QuestionsNavigationButtons closeModal />
      </Modal>
      <Flex style={{ width: "100%", height: "100%" }} vertical gap={8}>
        <AutoBreadcrumb />
        <Flex
          vertical
          gap={token.paddingXS}
          style={{
            background: token.colorBgBase,
            padding: token.paddingMD,
            borderRadius: token.borderRadius,
            width: "100%",
            height: "calc(100% - 30px)",
          }}
        >
          <ProForm<Exam>
            form={form}
            submitter={false}
            style={{ height: "100%" }}
          >
            <Row
              style={{
                height: "100%",
                flexFlow: !screens.lg ? "column-reverse" : undefined,
              }}
            >
              <Col
                md={24}
                lg={16}
                style={{
                  flex: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Flex
                  vertical
                  style={{ flex: "auto", overflow: "hidden" }}
                  gap={token.padding}
                  justify="space-between"
                >
                  <Tabs
                    style={{ overflow: "auto" }}
                    items={questionsTabsItems}
                    activeKey={activeQuestionKey}
                    renderTabBar={() => <></>}
                  />
                  <Flex justify="space-between">
                    <Button
                      type={"default"}
                      disabled={
                        questionsTabsItems.length <= 1 || isFirstQuestion
                      }
                      onClick={onPrevQuestion}
                    >
                      <LeftOutlined /> {screens.sm && "Предыдущий"}
                    </Button>
                    {questionsTabsItems.length <= 1 || isLastQuestion ? (
                      <Button type={"primary"} onClick={onTestFinishByUser}>
                        Завершить тест
                      </Button>
                    ) : (
                      <Button type={"primary"} onClick={onNextQuestion}>
                        Следующий <RightOutlined />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Col>

              <Col md={24} lg={1} style={{ flex: "0 auto" }}>
                <Flex
                  justify={"center"}
                  align={"center"}
                  style={{ height: "100%" }}
                >
                  <Divider
                    type={screens.lg ? "vertical" : "horizontal"}
                    style={{
                      margin: !screens.lg ? token.marginSM : token.marginLG,
                      height: !screens.lg ? "auto" : "100%",
                    }}
                  />
                </Flex>
              </Col>

              <Col
                md={24}
                lg={7}
                style={{
                  flex: !screens.lg ? "0 auto" : "auto",
                  maxHeight: "100%",
                  /* Minimum height for showing the first line of questions  */
                  minHeight: "110px",
                }}
              >
                <Flex
                  vertical
                  gap={token.paddingXS}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Flex
                    justify={deadlineCountdown ? "space-between" : "end"}
                    wrap="wrap-reverse"
                    gap={token.padding}
                  >
                    {deadlineCountdown && (
                      <Flex
                        vertical={screens.lg ? true : false}
                        align={screens.lg ? "start" : "center"}
                        gap={4}
                        justify="center"
                        wrap="wrap"
                        style={{ width: screens.sm ? "auto" : "100%" }}
                      >
                        <Typography.Text>Таймер</Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          <Statistic.Countdown
                            value={deadlineCountdown}
                            onFinish={onDeadlineFinish}
                          />
                        </Typography.Title>
                      </Flex>
                    )}
                    <Flex
                      justify="end"
                      style={{
                        width: screens.lg || !screens.sm ? "100%" : "auto",
                      }}
                    >
                      <Button
                        block={!screens.sm}
                        type={"primary"}
                        onClick={onTestFinishByUser}
                      >
                        Завершить тест
                      </Button>
                    </Flex>
                  </Flex>

                  {screens.sm ? (
                    <>
                      <Typography.Text>Навигация по вопросам</Typography.Text>
                      <div
                        style={{
                          maxHeight: !screens.lg ? "10vh" : "100%",
                          overflow: "auto",
                        }}
                      >
                        <QuestionsNavigationButtons />
                      </div>
                    </>
                  ) : (
                    <Button
                      block
                      onClick={() => {
                        setNavigationButtonsModal(true);
                      }}
                    >
                      Навигация по вопросам
                    </Button>
                  )}
                </Flex>
              </Col>
            </Row>
          </ProForm>
        </Flex>
      </Flex>
    </>
  );
};
export default Page;
