import React from "react";
import MeContextProvider from "@/entities/me/lib/context-provider";
import WithAuth from "@/shared/components/with-auth";
import SpaceContextProvider from "@/entities/space/lib/context-provider";
import { Outlet } from "react-router-dom";
import Pinger from "@/pages/space/pinger.tsx";
import Notifications from "./notifications";
import ContextDepsLoader from "./context-deps-loader";
import LayoutSupportButton from "./layout-support-button";
import StorageAvailabilityChecker from "@/pages/space/storage-availability-checker.tsx";

const Context: React.FC = () => {
  return (
    <SpaceContextProvider>
      <LayoutSupportButton />
      <WithAuth type={"required"}>
        <MeContextProvider>
          <Pinger>
            <Notifications>
              <ContextDepsLoader>
                <StorageAvailabilityChecker />
                <Outlet />
              </ContextDepsLoader>
            </Notifications>
          </Pinger>
        </MeContextProvider>
      </WithAuth>
    </SpaceContextProvider>
  );
};

export default Context;
