import React from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDigit,
  ProFormSelect,
  ProFormSelectProps,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Col, Divider, Flex, message, Row } from "antd";
import BaseQuestion from "@/entities/test/lib/question";
import { TestFormRecord } from "@/entities/test/ui/form";
import { v4 as uuidv4 } from "uuid";
import useMe from "@/entities/me/lib/use";
import { questionTestTypeValueEnum } from "@/entities/test/lib/question-type-value-enum";
import TestFormQuestionAnswerList from "./form-question-answer-list";

type Question = BaseQuestion & {
  is_deleted?: boolean;
};

type TestQuestionFormProps = {
  currentIndex: number;
  isSubmitButtonLoading: boolean;
  toggleIsSubmitButtonLoading: () => void;
  onAfterFinish?: () => void;
};

const TestQuestionFormFields: React.FC<TestQuestionFormProps> = React.memo(
  ({
    currentIndex,
    isSubmitButtonLoading,
    toggleIsSubmitButtonLoading,
    onAfterFinish,
  }) => {
    const member = useMe();
    const form = ProForm.useFormInstance<TestFormRecord>();
    const question = form.getFieldValue(["questions", currentIndex]);

    const [isQuestionDeleted, setIsQuestionDeleted] = React.useState(
      question?.is_deleted,
    );
    const [questionType, setQuestionType] = React.useState(question?.type);

    const minAnswersCount = question?.type === "short_answer" ? 1 : 2;

    const setQuestion = (question: Question) => {
      const questions = form.getFieldValue("questions");
      form.setFieldsValue({
        questions: [
          ...questions.slice(0, currentIndex),
          question,
          ...questions.slice(currentIndex + 1),
        ],
      });
    };

    const onTypeChange: ProFormSelectProps<Question["type"]>["onChange"] = (
      value,
    ) => {
      const question: Question = form.getFieldValue([
        "questions",
        currentIndex,
      ]);
      // При смене с одиночного на множественный и обратно, сбрасывается значение is_correct
      if (
        (question.type === "single" ||
          question.type === "multiple" ||
          question.type === "short_answer") &&
        (value === "single" || value === "multiple")
      ) {
        const answers = question.answers.map((answer) => {
          return {
            ...answer,
            is_correct: false,
          };
        });

        if (value === "multiple" || value === "single") {
          while (answers.length < 2) {
            answers.push({
              uuid: uuidv4(),
              is_correct: false,
              value: `Ответ ${answers.length + 1}`,
            });
          }
        }
        setQuestion({
          ...question,
          type: value,
          answers,
        });
        message.warning(
          "Из-за смены типа вопроса, сброшено значения правильности ответов",
        );
      } else if (value === "matching") {
        setQuestion({
          ...question,
          type: value,
          matching: Array.from({ length: 2 }, () => ({
            matched_value: "",
            matchable_value: "",
            matched_uuid: uuidv4(),
            matchable_uuid: uuidv4(),
          })),
          answers: [],
        });
      } else if (value === "short_answer") {
        setQuestion({
          ...question,
          type: value,
          answers: Array.from({ length: 1 }, () => ({
            uuid: uuidv4(),
            value: "Верное значение",
          })),
        });
      } else if (value === "sequence") {
        setQuestion({
          ...question,
          type: value,
          answers: Array.from({ length: 2 }, (_, index) => ({
            value: `Ответ ${index + 1}`,
            uuid: uuidv4(),
          })),
        });
      }
      setQuestionType(value);
    };

    const switchDeleteMark = () => {
      const questionsForm = form.getFieldValue(["questions"]);
      questionsForm[currentIndex].is_deleted =
        !questionsForm[currentIndex].is_deleted;

      setIsQuestionDeleted(questionsForm[currentIndex].is_deleted);

      form.setFieldsValue({ questions: questionsForm });
    };

    return (
      <Flex vertical style={{ width: "100%", height: "100%" }}>
        <ProFormCheckbox
          name={["questions", currentIndex, "is_deleted"]}
          hidden
        />

        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          {member.permissions.includes("course:update") && (
            <>
              <Button
                type={question?.is_deleted ? "default" : "primary"}
                danger={!isQuestionDeleted}
                onClick={switchDeleteMark}
              >
                {isQuestionDeleted ? "Восстановить вопрос" : "Удалить вопрос"}
              </Button>

              <Button
                type="primary"
                loading={isSubmitButtonLoading}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      toggleIsSubmitButtonLoading();
                      form.submit();
                    })
                    .catch((error) => {
                      message.error(
                        error?.errorFields?.[0]?.errors?.[0] ??
                          "Пожалуйста, исправьте ошибки перед сохранением.",
                      );
                      onAfterFinish?.();
                    });
                }}
              >
                Сохранить
              </Button>
            </>
          )}
        </Flex>
        <Divider />
        <Row style={{ width: "100%" }} justify={"space-between"}>
          <Col span={17}>
            <ProFormSelect<Question["type"]>
              name={["questions", currentIndex, "type"]}
              label={"Тип вопроса"}
              disabled={question?.disabled}
              valueEnum={questionTestTypeValueEnum}
              allowClear={false}
              rules={[{ required: true }]}
              onChange={onTypeChange}
            />
          </Col>
          <Col span={6}>
            <ProFormDigit
              name={["questions", currentIndex, "points"]}
              label={"Балл"}
              disabled
              rules={[{ required: true }]}
            />
          </Col>
        </Row>
        <ProFormText
          name={["questions", currentIndex, "uuid"]}
          hidden
          rules={[{ required: true }]}
        />
        <ProFormText
          name={["questions", currentIndex, "text"]}
          label={"Текст вопроса"}
          rules={[{ required: true }]}
        />
        <div
          style={{
            width: "100%",
            flex: "auto",
            overflowY: "auto",
          }}
        >
          <TestFormQuestionAnswerList
            currentIndex={currentIndex}
            name={question?.type === "matching" ? "matching" : "answers"}
            minAnswersCount={minAnswersCount}
            setQuestion={setQuestion}
          />
        </div>
      </Flex>
    );
  },
);
export default TestQuestionFormFields;
export type { TestQuestionFormProps };
