import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal, ModalProps } from "antd";
import { deepmerge } from "deepmerge-ts";
import PositionLearningRuleFormBatch, {
  PositionLearningRuleFormBatchProps,
} from "@/entities/position-learning-rule/ui/form-batch.tsx";

type PositionLearningRuleModalFormBatchProps =
  PositionLearningRuleFormBatchProps & {
    open?: ModalProps["open"];
    onCancel?: ModalProps["onCancel"];
    onOk?: () => void;
  };

const PositionLearningRuleModalFormBatch: React.FC<
  PositionLearningRuleModalFormBatchProps
> = ({ rest, onCancel, onOk, open: _open, ...formProps }) => {
  const [open, setOpen] = React.useState(false);

  let disabled = rest.type === "update" && rest.recordKey === undefined;

  const title =
    rest.type === "create"
      ? "Создание правил обучения"
      : "Редактирование правил обучения";

  const handleCancel = () => {
    setOpen(false);
  };

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterFinish;
    rest.onAfterFinish = async () => {
      onOk?.();
      await restOnAfterUpdate?.();
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterFinish;
    rest.onAfterFinish = async () => {
      setOpen(false);
      await restOnAfterCreate?.();
    };
  }

  const defaultFormProps: typeof formProps = {
    submitter: {
      render: (_, dom) => {
        return (
          <Flex gap={16} justify={"end"}>
            {dom}
          </Flex>
        );
      },
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);
  return (
    <>
      {rest.type === "create" && (
        <Button
          type={"primary"}
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          Создать
        </Button>
      )}
      <Modal
        width={"1200px"}
        open={_open ?? open}
        title={title}
        destroyOnClose
        onCancel={onCancel ?? handleCancel}
        footer={null}
      >
        <PositionLearningRuleFormBatch {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default PositionLearningRuleModalFormBatch;
export type { PositionLearningRuleModalFormBatchProps };
