import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";
import PoolForm, { PoolFormProps } from "@/entities/pool/ui/form.tsx";

type PoolModalFormProps = PoolFormProps & {
  isArchivedCourse?: () => boolean;
};

const PoolModalForm: React.FC<PoolModalFormProps> = ({
  rest,
  isArchivedCourse,
  ...formProps
}) => {
  const [open, setOpen] = React.useState(false);

  const triggerText = rest.type === "create" ? "Создать" : "Настройки";
  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }

  const title = rest.type === "create" ? "Создание потока" : "Настройка курса";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  const defaultFormProps: Omit<typeof formProps, "courseId"> = {
    submitter: {
      render: (_, dom) => {
        return <Flex justify={"end"}>{dom}</Flex>;
      },
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      <Button
        type={"primary"}
        disabled={disabled}
        onClick={() => {
          if (isArchivedCourse?.()) return;
          setOpen(true);
        }}
      >
        {triggerText}
      </Button>
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <PoolForm {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default PoolModalForm;
export type { PoolModalFormProps };
