import React from "react";
import { Space } from "@/models";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Badge, Flex, Space as AntSpace, Typography } from "antd";
import axios from "@/axios";
import BatchUpdate from "@/pages/admin/spaces/batch-update";
import statusValueEnum from "@/entities/space/lib/status-value-enum";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import ProTableForAdmin from "../pro-table-for-admin";
import bytesToHumanReadable from "@/shared/helpers/bytes-to-human-readable";
import LicenseModalForm from "@/entities/license/ui/modal-form.tsx";
import useParentHeight from "@/shared/hooks/use-parent-height";

const Page: React.FC = () => {
  /** Logic */

  const [someSelected, setSomeSelected] = React.useState(false);
  const { parentHeight, ref } = useParentHeight();
  /** Render */

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <ProTableForAdmin<Space>
        rowKey={"id"}
        hasQueryParams
        scroll={{
          x: "1600px",
          y: parentHeight - (someSelected ? 292 : 242),
        }}
        pagination={{ showSizeChanger: true, defaultPageSize: 100 }}
        request={async (params, sort, filter) => {
          const config: AxiosRequestConfig = {
            method: "POST",
            url: "/api/spaces/search",
            ...axiosConfigAdapter(params, sort, filter),
          };

          config.data.includes.push({ relation: "license" });

          const data = await axios<OrionRestIndexResponse<Space>>(config).then(
            (response) => response.data,
          );

          return {
            data: data.data,
            total: data.meta.total,
            success: true,
          };
        }}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            sorter: true,
            copyable: true,
            width: 100,
            fixed: "left",
          },
          {
            title: "UUID",
            dataIndex: "uuid",
            ellipsis: true,
            sorter: true,
            copyable: true,
            width: 100,
            fixed: "left",
          },
          {
            title: "Адрес пространства",
            dataIndex: "slug",
            render: (_, { url, host }) => {
              return (
                <a href={url} target={"_blank"} rel={"noreferrer"}>
                  {host}
                </a>
              );
            },
            sorter: true,
            width: 250,
            fixed: "left",
          },
          {
            title: "Статус",
            dataIndex: "status",
            valueEnum: statusValueEnum,
            width: 150,
            fixed: "left",
          },
          {
            title: "Дата-время истечения активации",
            dataIndex: ["license", "expires_at"],
            valueType: "dateTime",
            fieldProps: { format: dateSTime },
            width: 150,
            ellipsis: true,
          },
          {
            title: "Максимальное количество активных участников",
            dataIndex: ["license", "max_active_members"],
            width: 150,
            sorter: true,
          },
          {
            title: "Максимальный объем используемой памяти",
            dataIndex: ["license", "max_memory_usage"],
            width: 150,
            renderText: (value: number) =>
              value ? bytesToHumanReadable(value) : "-",
            sorter: true,
          },
          {
            title: "Название компании",
            dataIndex: "company_name",
            sorter: true,
            ellipsis: true,
          },
          {
            title: "АП",
            tooltip: "Активных пользователей",
            dataIndex: "active_members_count",
            sorter: true,
            render: (value) => {
              return (
                <AntSpace>
                  <Badge status={"success"} />
                  {value}
                </AntSpace>
              );
            },
            width: 50,
          },
          {
            title: "ДП",
            tooltip: "Деактивированных пользователей",
            dataIndex: "deactivated_members_count",
            sorter: true,
            render: (value) => {
              return (
                <AntSpace>
                  <Badge status={"error"} />
                  {value}
                </AntSpace>
              );
            },
            width: 50,
          },
          {
            title: "Использовано памяти",
            dataIndex: "memory_usage",
            sorter: true,
            renderText: (value: number) =>
              value ? bytesToHumanReadable(value) : "-",
            ellipsis: true,
          },
          {
            title: "ФИО контактного лица",
            dataIndex: "contact_full_name",
            sorter: true,
            width: 150,
            ellipsis: true,
          },
          {
            title: "Телефон контактного лица",
            dataIndex: "contact_phone_number",
            sorter: true,
            copyable: true,
            width: 150,
          },
          {
            title: "Почта контактного лица",
            dataIndex: "contact_email",
            sorter: true,
            copyable: true,
            width: 150,
            ellipsis: true,
          },
          {
            title: "created_at",
            sorter: true,
            defaultSortOrder: "descend",
            dataIndex: "created_at",
            hideInTable: true,
          },
          {
            title: "Действия",
            width: 200,
            fixed: "right",
            hideInSearch: true,
            render: (_, { id }, __, action) => (
              <Flex vertical>
                <BatchUpdate
                  key={"batch-update"}
                  rest={{
                    recordKeys: [id],
                    onAfterBatchUpdate: () => action!.reload(),
                  }}
                  trigger={<Typography.Link>Редактировать</Typography.Link>}
                />
                <LicenseModalForm
                  rest={{
                    type: "update",
                    recordKey: id,
                    onAfterUpdate: () => action!.reload(),
                  }}
                  trigger={
                    <Typography.Link>Редактировать лицензию</Typography.Link>
                  }
                />
              </Flex>
            ),
          },
        ]}
      />
    </div>
  );
};
export default Page;
