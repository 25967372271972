import { Tooltip } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

const PoolTemplateIcon: React.FC = () => {
  return (
    <Tooltip title="Шаблон потока">
      <FileTextOutlined />
    </Tooltip>
  );
};
export default PoolTemplateIcon;
