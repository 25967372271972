import {
  ProForm,
  ProFormCheckbox,
  ProFormList,
  ProFormListProps,
  ProFormText,
  ProFormRadio,
} from "@ant-design/pro-components";
import { TestFormRecord } from "./form";
import { Button, Col } from "antd";
import {
  CaretDownOutlined,
  CaretUpFilled,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Question, { Answer, AnswerWithIsCorrect } from "../lib/question";
import { v4 as uuidv4 } from "uuid";

type TestFormQuestionAnswerListProps = {
  currentIndex: number;
  minAnswersCount: number;
  setQuestion: (question: Question) => void;
  name: "matching" | "answers";
};

const TestFormQuestionAnswerList: React.FC<TestFormQuestionAnswerListProps> = ({
  currentIndex,
  minAnswersCount,
  name,
  setQuestion,
}) => {
  const form = ProForm.useFormInstance<TestFormRecord>();
  const question = form.getFieldValue(["questions", currentIndex]);

  const IsCorrectComponent =
    question?.type === "multiple" ? ProFormCheckbox : ProFormRadio;

  const onAfterAddAnswer: ProFormListProps<Question>["onAfterAdd"] = (
    _,
    addedIndex,
  ) => {
    const question: Question = form.getFieldValue(["questions", currentIndex]);
    if (question.type === "matching") {
      question.matching[addedIndex!] = {
        matched_uuid: uuidv4(),
        matchable_uuid: uuidv4(),
        matched_value: "",
        matchable_value: "",
      };
    } else {
      question.answers[addedIndex!] =
        question.type === "short_answer" || question.type === "sequence"
          ? { value: "", uuid: uuidv4() }
          : { is_correct: false, value: "", uuid: uuidv4() };
    }
    setQuestion(question);
  };

  const validateCorrectAnswer = () => {
    const question = form.getFieldValue(["questions", currentIndex]);
    if (question) {
      if (
        (question.type === "single" || question.type === "multiple") &&
        !question.answers.some(
          (answer: AnswerWithIsCorrect) => answer.is_correct,
        )
      ) {
        return Promise.reject(
          new Error("Вопрос должен иметь хотя бы один правильный ответ"),
        );
      } else if (
        question.type === "short_answer" ||
        question.type === "sequence"
      ) {
        const valuesSet = new Set(
          question.answers.map((answer: Answer) =>
            answer.value.trim().toLowerCase(),
          ),
        );
        if (valuesSet.size !== question.answers.length) {
          return Promise.reject(
            new Error("Вариант ответа с данным значением уже указан"),
          );
        }
      }
    }

    return Promise.resolve();
  };

  const onIsCorrectChange = (event: any) => {
    // questions_0_answers_2_is_correct
    //                     ^
    const targetIndex = Number(event.target.id!.split("_")[3]);

    const question: Question = form.getFieldValue(["questions", currentIndex]);

    if (question.type !== "single") return;

    setQuestion({
      ...question,
      answers: question.answers.map((answer, index) => {
        return {
          ...answer,
          is_correct: index === targetIndex,
        };
      }),
    });
  };

  return (
    <ProFormList
      style={{ marginBottom: 0 }}
      name={["questions", currentIndex, name]}
      label={"Варианты ответов"}
      creatorButtonProps={{
        type: "primary",
        position: "bottom",
        creatorButtonText: "Добавить вариант ответа",
      }}
      min={minAnswersCount}
      max={10}
      rules={[{ validator: validateCorrectAnswer }]}
      onAfterAdd={onAfterAddAnswer}
      actionRender={(fieldProps, { remove, move }) => {
        const lastIndex =
          form.getFieldValue(["questions", currentIndex, name]).length - 1;

        return [
          <Button
            key={"move-down"}
            icon={<CaretDownOutlined />}
            type={"text"}
            disabled={fieldProps.name === lastIndex}
            onClick={() => {
              move(fieldProps.name, fieldProps.name + 1);
            }}
          />,
          <Button
            key={"move-up"}
            icon={<CaretUpFilled />}
            type={"text"}
            disabled={fieldProps.name === 0}
            onClick={() => {
              move(fieldProps.name, fieldProps.name - 1);
            }}
          />,
          <Button
            key={"duplicate"}
            icon={<CopyOutlined />}
            type={"text"}
            disabled={lastIndex + 1 >= 10}
            onClick={() => {
              const defaultValue = {
                ...form.getFieldValue([
                  "questions",
                  currentIndex,
                  name,
                  fieldProps.name,
                ]),
              };

              const questions = form.getFieldValue(["questions"]);

              if (questions[currentIndex].type === "single") {
                defaultValue.is_correct = false;
              }

              if (questions[currentIndex].type === "matching") {
                defaultValue.matched_uuid = uuidv4();
                defaultValue.matchable_uuid = uuidv4();
              }

              const newAnswers = [...questions[currentIndex][name]];

              newAnswers.splice(fieldProps.name + 1, 0, {
                ...defaultValue,
                uuid: uuidv4(),
              });

              form.setFieldValue(["questions", currentIndex, name], newAnswers);
            }}
          />,
          <Button
            key={"remove"}
            icon={<DeleteOutlined />}
            disabled={lastIndex + 1 <= minAnswersCount}
            type={"text"}
            onClick={() => {
              remove(fieldProps.name);
            }}
          />,
        ];
      }}
    >
      {(_, index) => (
        <ProForm.Group grid rowProps={{ gutter: 0 }}>
          {name === "matching" ? (
            <>
              <Col span={11}>
                <ProFormText
                  key={`${name}_${index}_matched_value`}
                  name={"matched_value"}
                  rules={[{ required: true }]}
                  allowClear={false}
                  placeholder={"Ответ"}
                />
              </Col>
              <Col span={1} style={{ textAlign: "center" }}>
                {"-"}
              </Col>
              <Col span={11}>
                <ProFormText
                  key={`${name}_${index}_matchable_value`}
                  name={"matchable_value"}
                  rules={[{ required: true }]}
                  allowClear={false}
                  placeholder={"Cоответствие"}
                />
              </Col>
              <ProFormText
                key={`${name}_${index}_matched_uuid`}
                name={"matched_uuid"}
                hidden
                rules={[{ required: true }]}
              />
              <ProFormText
                key={`${name}_${index}_matchable_uuid`}
                name={"matchable_uuid"}
                hidden
                rules={[{ required: true }]}
              />
            </>
          ) : (
            <>
              {(question.type === "multiple" || question.type === "single") && (
                <IsCorrectComponent
                  key={`${name}_${index}_is_correct`}
                  name={"is_correct"}
                  valuePropName={"checked"}
                  rules={[{ required: true }]}
                  fieldProps={{
                    onChange: onIsCorrectChange,
                  }}
                >
                  Правильный ответ
                </IsCorrectComponent>
              )}
              <ProFormText
                key={`${name}_${index}_value`}
                name={"value"}
                rules={[{ required: true }]}
                allowClear={false}
              />
              <ProFormText
                key={`${name}_${index}_uuid`}
                name={"uuid"}
                hidden
                rules={[{ required: true }]}
              />
            </>
          )}
        </ProForm.Group>
      )}
    </ProFormList>
  );
};

export default TestFormQuestionAnswerList;
