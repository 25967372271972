import React from "react";
import {
  ProForm,
  ProFormSelect,
  ProFormSelectProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { Space } from "@/models";
import useSWR from "swr";
import { debounce } from "lodash";

type SpaceSearchSelectProps = ProFormSelectProps & {
  listName?: string;
};

const SpaceSearchSelect: React.FC<SpaceSearchSelectProps> = ({
  fieldProps,
  listName,
  ...selectProps
}) => {
  const form = ProForm.useFormInstance();
  const [searchValue, setSearchValue] = React.useState("");
  const [initialValue, setInitialValue] = React.useState();

  React.useEffect(() => {
    setInitialValue(form.getFieldValue(listName ?? selectProps.name));
  }, []);

  const {
    data: spaces,
    isLoading,
    error,
  } = useSWR(
    ["/api/spaces/search", searchValue, initialValue],
    async ([url]) => {
      const filters: any = [];

      if (searchValue) {
        filters.push({
          field: "slug",
          operator: "ilike",
          value: `%${searchValue}%`,
        });
      }

      const res = await axios
        .post<{ data: Space[] }>(url, {
          filters,
        })
        .then(async (res) => {
          const data: any = [];

          res.data.data.forEach((space) => {
            data.push({
              label: space.slug,
              value: space.id,
            });
          });

          if (
            initialValue &&
            typeof initialValue !== "object" &&
            !data.some((option: any) => option.value === initialValue)
          ) {
            const currentData = await axios
              .get(`/api/spaces/${initialValue}`)
              .then(({ data }) => ({
                label: data.data.name,
                value: data.data.id,
              }));
            data.unshift(currentData);
          }

          return data;
        });
      return res;
    },
  );

  if (error) throw error;

  const onSearch = debounce((value) => setSearchValue(value), 500);

  return (
    <ProFormSelect
      options={spaces}
      showSearch
      fieldProps={{
        onSearch: onSearch,
        loading: isLoading,
        ...fieldProps,
      }}
      {...selectProps}
    />
  );
};

export default SpaceSearchSelect;
