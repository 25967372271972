import React from "react";
import { Member as BaseMember, Space } from "@/models";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Flex, Tag, Typography } from "antd";
import axios from "@/axios";
import { date, dateSTime } from "@/shared/dayjs/lib/formats";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { ProTableProps } from "@/shared/ant-design-pro-components/table/ui";
import MemberResetPasswordButton from "@/features/member/reset-password/ui/button";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum";
import MemberUpdateModalForm from "@/pages/admin/members/member-modal-form";
import { ActionType, ProFormSelect } from "@ant-design/pro-components";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import SpaceSearchSelect from "@/entities/space/ui/select-with-swr";
import ProTableForAdmin from "@/pages/admin/pro-table-for-admin.tsx";
import { keycloakStatusValueEnum } from "@/entities/member/lib/keycloak-status-value-enum.tsx";

type Member = Omit<BaseMember, "space"> & { space: Space };
type MembersTableProps = ProTableProps<Member, Partial<Member>>;

const Page: React.FC = () => {
  const { parentHeight, ref } = useParentHeight("table");
  const [total, setTotal] = React.useState<number>();
  const actionRef = React.useRef<ActionType>(null);

  const defaultProps: MembersTableProps = {
    rowKey: "id",
    actionRef,
    pagination: { showSizeChanger: true },
    request: async (
      params,
      sort,
      filter: Record<string, (string | number | null)[] | null>,
    ) => {
      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/members/search`,
        ...axiosConfigAdapter(params, sort, filter),
      };

      config.data.sort.push({
        field: "id",
        direction: "desc",
      });

      config.data.includes.push({ relation: "role" }, { relation: "space" });
      const data = await axios
        .request<OrionRestIndexResponse<Member>>(config)
        .then((res) => res.data);

      setTotal(data.meta.total);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    rowSelection: false,
    scroll: {
      y: parentHeight + (total ? 0 : 40) - 143,
      x: "1200",
    },
    hasQueryParams: true,
    columns: [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
        align: "center",
        width: 100,
        fixed: "left",
      },
      {
        title: "ФИО",
        dataIndex: "full_name",
        sorter: true,
        width: 125,
        fixed: "left",
      },
      {
        title: "Пространство",
        dataIndex: "space_id",
        sorter: true,
        render: (_, { space: { host, url } }) => {
          return (
            <a href={url} target={"_blank"} rel={"noreferrer"}>
              {host}
            </a>
          );
        },
        width: 250,
        renderFormItem: (_, config) => (
          <SpaceSearchSelect {...config} mode="multiple" />
        ),
      },
      {
        title: "Роль",
        dataIndex: "role_id",
        sorter: true,
        render: (_, { role }) => (
          <Tag color={role.color} bordered>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{ color: invertColor(role.color), maxWidth: "120px" }}
            >
              {role.name}
            </Typography.Text>
          </Tag>
        ),
        width: 150,
      },
      {
        title: "Роль ID",
        dataIndex: "role_id",
        sorter: true,
        width: 75,
        valueType: "digit",
      },
      {
        title: "Почта",
        dataIndex: "email",
        sorter: true,
        copyable: true,
        width: 125,
      },
      {
        title: "Логин",
        dataIndex: "username",
        sorter: true,
        copyable: true,
        width: 125,
      },
      {
        title: "Изоляция доступа по подразделениям",
        dataIndex: "is_restricted_access",
        valueType: "boolean",
        sorter: true,
        width: 150,
      },
      {
        title: () => "Дата рождения",
        dataIndex: "birth_date",
        sorter: true,
        valueType: "dateTime",
        fieldProps: { format: date },
        width: 125,
      },
      {
        title: "Статус",
        dataIndex: "status",
        sorter: true,
        valueType: "select",
        valueEnum: statusValueEnum,
        width: 125,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: "Статус пользователя в Keycloak",
        dataIndex: "keycloak_user_status",
        sorter: true,
        valueType: "select",
        width: 125,
        valueEnum: keycloakStatusValueEnum,
      },
      {
        title: "Идентификатор пользователя в Keycloak",
        dataIndex: "keycloak_user_sub",
        sorter: true,
        width: 150,
        copyable: true,
      },
      {
        title: "Дата добавления",
        dataIndex: "created_at",
        valueType: "dateTime",
        sorter: true,
        defaultSortOrder: "descend",
        width: 125,
        renderFormItem(_, config) {
          return (
            <DateTimeRangePickerWithTimeControls
              name={["range", "created_at"]}
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: "Дата изменения",
        dataIndex: "updated_at",
        sorter: true,
        valueType: "dateTime",
        width: 125,
      },
      {
        title: "СНИЛС",
        dataIndex: "snils",
        sorter: true,
        width: 125,
      },
      {
        title: "Телефон",
        dataIndex: "phone",
        sorter: true,
        width: 125,
      },
      {
        title: "Тема",
        dataIndex: "theme",
        sorter: true,
        width: 125,
      },
      {
        title: "Storage proxy",
        dataIndex: "is_storage_proxy_enabled",
        sorter: true,
        valueType: "boolean",
        width: 125,
      },
      {
        title: "Аватар ID",
        dataIndex: "avatar_image_file_id",
        sorter: true,
        width: 125,
      },
      {
        title: "Действия",
        width: 150,
        fixed: "right",
        hideInSearch: true,
        render: (_, { id, space }) => (
          <Flex vertical>
            <MemberUpdateModalForm
              space={space}
              trigger={<Typography.Link>Редактировать</Typography.Link>}
              rest={{
                type: "update",
                recordKey: [id],
                onAfterUpdate: () => actionRef.current?.reload(),
              }}
            />
            <MemberResetPasswordButton
              key="reset-password"
              membersIds={[id]}
              onAfterFinish={() => actionRef.current?.reload()}
              trigger={<Typography.Link>Сбросить пароль</Typography.Link>}
            />
          </Flex>
        ),
      },
    ],
  };

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <ProTableForAdmin<Member> {...defaultProps} />
    </div>
  );
};
export default Page;
